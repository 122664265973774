import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

export default function MainNavbar(props) {
  
  return (
    <>
    <nav className={`navbar navbar-expand-lg navbar-${props.swithchmode} bg-${props.swithchmode}
    fixed-top navbar-light bg-dark border-2`}>
    
  <div className="container-fluid">
    <Link className="navbar-brand fw-bold text-light" to="/Admin/">{props.titles}</Link>
    <button className="navbar-toggler text-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link  className="nav-link active text-light" aria-current="page" to="/customer/">Home</Link>
        </li>
        <li className="nav-item">
          <Link  className="nav-link active text-light" aria-current="page" to="/customer/customerverification">Customer Verification</Link>
        </li>
       

      </ul>
      <button className="btn btn-outline-primary text-light" onClick={props.logout}>Logout</button>
    </div>
  </div>
</nav>
    </>
  )
}

MainNavbar.propTypes = {
    title: PropTypes.string.isRequired,
    about: PropTypes.string
}

MainNavbar.defaultProps ={
    title: 'Set Title',
    about: 'Set about'
}