import './App.css';
import React from 'react'; // Add this import statement
import Guest from './Component/Admin/Admin';
import Auth from './Component/User/User';
import Cus from './Component/Customer/Customer';
import AuthUser from './Component/Authuser';

function App() {
  
  const { getToken,user } = AuthUser();

  if (!getToken()) {
    return <Auth />; 
  }
  else{
  if(user.usertype === "Admin"){
    return <Guest />;
  }else{

    return < Cus />;
  }}
  
}

export default App;
