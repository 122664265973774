import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import AuthUser from './Authuser';


function AddProjects() {
  const {http} = AuthUser();
  const [sliderImages, setSliderImages] = useState([]);
  const [sliderprevImages, setSliderprevImages] = useState([]);
  const [projects, setProjects] = useState([]);
  // State for multiple slider images
  const [thumbnailImages, setThumbnailImages] = useState([]);
  const [thumbnailsavImages, setThumbnailsavImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isProjectNameTaken, setIsProjectNameTaken] = useState(false);
  //const isItemSelectedInArray = dataArray.includes(selectedItem);
  const [projectInfo, setProjectInfo] = useState({
    title: '',
     // Default product type
    projecttype:'',
    projectname:'',
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectInfo({
      ...projectInfo,
      [name]: value,
    });
  };
  useEffect(() => {
    http.get('/projects') // Replace with the actual API endpoint
            .then((response) => {
                const projectNamesArray = response.data.projects.map((project) => project.projectname);
                setProjects(projectNamesArray);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
            
  }, []);

  
  const handleFileInputChange = (e, imageType) => {
    const files = e.target.files;
    if (files.length > 0) {
      // Filter valid images
      const validImages = Array.from(files).filter((file) =>
        ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(file.type)
      );

      if (imageType === 'slider') {
        setCurrentImageIndex(0);
        setSliderImages(e.target.files);
        console.log(sliderImages.length);
        setSliderprevImages(validImages.map((file) => URL.createObjectURL(file)));
        
      } else if (imageType === 'thumbnail') {
        setThumbnailsavImages(e.target.files);
        setThumbnailImages(validImages.map((file) => URL.createObjectURL(file)));

      } else {
        alert('Invalid image type');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(projects);
    const existingProject = projects.find((project) => project === projectInfo.projectname.trim());
    if (existingProject) {
        setIsProjectNameTaken(true);
        return; // Stop form submission
      }

  setIsProjectNameTaken(false);
    
    const formData = new FormData();
     formData.append('projectname', projectInfo.projectname);
formData.append('projecttype', projectInfo.projecttype);
// console.log(formData);
//formData.append('images', JSON.stringify(sliderImages));
    // Append the selected images to the form data
    // for (let i = 0; i < sliderImages.length; i++) {  
    //   //console.log(sliderImages); 
    //   formData.append('images[]', sliderImages[i]);
      
    // }
    for (let i = 0; i < sliderImages.length; i++) {

      console.log(i);

      formData.append('slider_images[]', sliderImages[i]);
  }

  // Append the selected thumbnail images to the form data
  for (let i = 0; i < thumbnailsavImages.length; i++) {
    
      formData.append('thumbnail_images[]', thumbnailsavImages[i]);
  }
  
    try {
      const response = await http.post('/save-project', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.data.message === 'Product saved successfully') {
        // Reset the form or show a success message
        console.log('Product saved successfully');
      }
      else{
        console.error('Error:', response.data);
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error saving product:', error);
    }
  };

  const goToPreviousImage = () => {
    if (sliderImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? sliderprevImages.length - 1 : prevIndex - 1
      );
    }
  };

  const goToNextImage = () => {
    if (sliderImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === sliderprevImages.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  useEffect(() => {
    if (sliderprevImages.length === 0) {
      setCurrentImageIndex(0); // Reset the current image index when the sliderImages array is empty
    }
  }, [sliderprevImages]);

  useEffect(() => {
    // Implement a timer to automatically advance the slideshow
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sliderprevImages.length);
    }, 3000); // Adjust the interval (in milliseconds) for the slideshow

    // Clear the timer when the component unmounts
    return () => clearInterval(timer);
  }, [sliderImages]);

  return (
    <>
    <div className="container mt-5">
    <form onSubmit={handleSubmit}>
    <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Project Name
          </label>
          <input
            type="text"
            className="form-control"
            id="projectname"
            name="projectname"
            value={projectInfo.projectname}
            onChange={handleInputChange}
            required
          />
          {isProjectNameTaken && (
  <div className="alert alert-danger" role="alert">
    This project name already exists. Please choose a different name.
  </div>
)}
        </div>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Project Type
          </label>
          <input
            type="text"
            className="form-control"
            id="projecttype"
            name="projecttype"
            value={projectInfo.projecttype}
            onChange={handleInputChange}
            required
          />

        </div>
        
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
        </form>
        </div>

          <div className="card m-3">
            <h2 className="card-header">Product Images</h2>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="fileInput1"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileInputChange(e, 'slider')}
                      multiple
                      accept="image/jpeg, image/png, image/jpg, image/gif"
                    />
                    <label
                      htmlFor="fileInput1"
                      className="form-control border border-primary p-1 cursor-pointer d-flex justify-content-center align-items-center"
                    >
                      {sliderprevImages.length > 0 ? (
                        <>
                          <button className="btn btn-link" onClick={() => goToPreviousImage()}>
                            &lt;
                          </button>
                          <img
                            src={sliderprevImages[currentImageIndex]}
                            alt={`Slider Preview ${currentImageIndex}`}
                            key={currentImageIndex}
                            className="img-fluid"
                          />
                          <button className="btn btn-link" onClick={() => goToNextImage()}>
                            &gt;
                          </button>
                        </>
                      ) : (
                        <i className="fa fa-cloud-upload-alt fa-4x ml-1"></i>
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="fileInput2"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileInputChange(e, 'thumbnail')}
                      accept="image/jpeg, image/png, image/jpg, image/gif"
                    />
                    <label
                      htmlFor="fileInput2"
                      className="form-control border border-primary p-1 cursor-pointer d-flex justify-content-center align-items-center"
                    >
                      {thumbnailImages && thumbnailImages.length > 0 ? (
                        <img src={thumbnailImages[0]} alt="Thumbnail Preview" className="img-fluid" />
                      ) : (
                        <i className="fa fa-cloud-upload-alt fa-4x ml-1"></i>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
  );
}

export default AddProjects;
