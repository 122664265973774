import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthUser from './Authuser';

function ProjectList() {
    const {http} = AuthUser();
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        // Make the API request when the component mounts
        http.get('/projects') // Replace with the actual API endpoint
            .then((response) => {
                setProjects(response.data.projects);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);
    

    const handleTitleClick = (projectname,projecttype) => {
        // Navigate to the new route with the projectname parameter in the URL
        console.log(projecttype);
        navigate(`/showproperties`,{state:{projectname,projecttype}});

        
    };
    
    return (
        <div className="container">
            <div className="row h-50">
                {projects.map((project) => (
                    <div className="col-md-4" key={project.id}>
                        <div className="card mb-4">
                            <img
                                src={project.imagePath}
                                alt={project.projectname}
                                className="card-img-top"
                                style={{ width: '100%', height: '300px' }}
                            />
                            <div className="card-body text-center" style={{ fontFamily: 'Time new Roman', fontSize: '16px' }}>
                                <h2 className="card-title" onClick={() => handleTitleClick(project.projectname ,project.projecttype)}>
                                    {project.projectname}
                                </h2>
                                {/* Add other project details as needed */}
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProjectList;
