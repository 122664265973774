import React, { useState, useEffect } from 'react';
import AuthUser from './Authuser';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
import Updatecustomer from './UpdateCustomer';


function CustomerList() {
  const {http} = AuthUser();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  



  useEffect(() => {
    // Make an http GET request to fetch customer data
    http.put('/customers') // Adjust the URL as needed
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching customer data:', error);
      });
  }, []);

  const handleEditClick = (customer) => {
    // Handle edit action here
    setSelectedCustomer(customer);
    setShowModal(true);
  };
  const updateCustomer = (updatedCustomers) => {
    setCustomers(updatedCustomers);
    
  };
  
  const handleDeleteClick = async (cusid) => {

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {

      if (willDelete) {
        try {
           http.delete(`/delete-customer/${cusid}`); // Replace with your API endpoint
          // Remove the deleted item from the state
          updateCustomer((customers) => customers.filter((item) => item.Cus_code !== cusid));
        } catch (error) {
          console.error('Error deleting data:', error);
        }
        swal("Poof! Your imaginary file has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your imaginary file is safe!");
      }
    });
    
  }

  return (
    <div>
      <h1>Customer List</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <tr key={customer.id}>
              <td>{customer.id}</td>
              <td>{customer.Customer_Name}</td>
              <td>{customer.Email}</td>
              <td>
                <Button variant="primary" onClick={() => handleEditClick(customer)}>
                  Edit
                </Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteClick(customer.Cus_code)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showModal && (
        <Updatecustomer
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setSelectedCustomer(null);
          }}
          customer={selectedCustomer}
          updateCustomer={updateCustomer}
        />
      )}
      
    </div>
  );
}

export default CustomerList;
