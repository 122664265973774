import React, { useState, useEffect } from 'react';
import AuthUser from './Authuser';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
 import swal from 'sweetalert';
function Showpaymentin() {
  
  const [sales, setsales] = useState([]);
  const { http } = AuthUser();
  const Navigate = useNavigate();
  const updatesale = (updatedsales) => {
    setsales(updatedsales);
  };

  const handleEditClick = (sale) => {
    //  Handle edit action here
    
      // setSelectedsale(sale);
      // setShowModal(true);
      const update = "Update";
      
      Navigate(`/Admin/paymentin`,{state:{update,sale}});
    };

  const handleDeleteClick = async (cusid) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await http.delete(`/delete-sale/${cusid}`);
          // Remove the deleted item from the state
          updatesale((sales) => sales.filter((item) => item.unique_code !== cusid));
          swal("Poof! Your sale file has been deleted!", {
            icon: "success",
          });
        } catch (error) {
          console.error('Error deleting data:', error);
        }
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  };

  useEffect(() => {
    try {
      // Make an http GET request to fetch nominee data
      http.get('/paymentin')
        .then((response) => {
          setsales(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error('Error fetching nominee data:', error);
        });
    } catch (error) {
      console.error('An error occurred while fetching nominee data:', error);
    }
  }, []);
  
    return (
      <div>
        <h1>sale List</h1>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>Invoice</th>
            <th>Customer Name</th>
            
            <th>Received Amount</th>
           
          </tr>
        </thead>
        <tbody>
          {sales.map((sale) => (
            
              <tr>
                <td>{sale.invoiceno}</td>
                <td>{sale.customername}</td>
                
                <td>{sale.recivedamount}</td>
                
                <td><Button variant="primary" onClick={() => handleEditClick(sale)}>
                    Edit
                  </Button>{' '}
                  <Button variant="danger" onClick={() => handleDeleteClick(sale.invoiceno)}>
                    Delete
                  </Button></td>
              </tr>
          ))}
        </tbody>

      </Table>
        {/* {showModal && (
          // <Updatesale
          //   show={showModal}
          //   handleClose={() => {
          //     setShowModal(false);
          //     setSelectedsale(null);
          //   }}
          //   sale={selectedsale}
          //   updatesale={updatesale}
          // />
        )} */}
        
      </div>
    );
}

export default Showpaymentin