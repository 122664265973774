import React, { useState, useEffect } from 'react';
import AuthUser from './Authuser';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Updatenominee from './UpdateNominee';
import swal from 'sweetalert';
function ShowNominee() {
  const {http} = AuthUser();
    const [nominees, setnominee] = useState([]);
    const [selectednominee, setSelectednominee] = useState(null);
    const [showModal, setShowModal] = useState(false);
  
    useEffect(() => {
        try {
          // Make an http GET request to fetch nominee data
          http.put('/nominees')
            .then((response) => {
                setnominee(response.data);
              console.log(nominees);
            })
            .catch((error) => {
              console.error('Error fetching nominee data:', error);
            });
        } catch (error) {
          console.error('An error occurred while fetching nominee data:', error);
        }
      }, []);
      
     
      const handleEditClick = (nominee) => {
       // Handle edit action here
        setSelectednominee(nominee);
        setShowModal(true);
      };
      const updatenominee = (updatednominees) => {
        setnominee(updatednominees);
        
      };
      const handleDeleteClick = async (cusid) => {
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this imaginary file!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            try {
               http.delete(`/delete-nominee/${cusid}`); // Replace with your API endpoint
              // Remove the deleted item from the state
              updatenominee((nominees) => nominees.filter((item) => item.Nom_code !== cusid));
            } catch (error) {
              console.error('Error deleting data:', error);
            }
            swal("Poof! Your imaginary file has been deleted!", {
              icon: "success",
            });
          } else {
            swal("Your imaginary file is safe!");
          }
        });
        
      };
  return (
    <div>
      <h1>nominee List</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {nominees.map((nominee) => (
            <tr key={nominee.id}>
              <td>{nominee.id}</td>
              <td>{nominee.Nominee_Name}</td>
              <td>{nominee.Email}</td>
              <td>
                <Button variant="primary" onClick={() => handleEditClick(nominee)}>
                  Edit
                </Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteClick(nominee.Nom_code)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      
      {showModal && (
        <Updatenominee
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setSelectednominee(null);
          }}
          nominee={selectednominee}
          updatenominee={updatenominee}
        />
      )}
      
    </div>
  )
}

export default ShowNominee