// CustomerForm.js
import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import AuthUser from './Authuser';
import Table from 'react-bootstrap/Table';



function CustomerForm() {
  const {user,http} = AuthUser();
  
  const [customer, setCustomer] = useState([]);
  const [products , setproducts] = useState([]);
  const [recieved , setrecieved] = useState([]);
  
  const [activeTab, setActiveTab] = useState(1);
  
  const handleTabClick = (tabNumber) => {
    
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    // Make an Axios GET request to fetch customer data
    http.put(`/customer/${user.email}`)// Adjust the URL as needed
      .then((response) => {
        setCustomer(response.data);
      })
      .catch((error) => {
        console.error('Error fetching customer data:', error);
      });   
  }, []);
  
  useEffect(() => {
    // Assuming customer is an array of customer objects
    customer.forEach((customer) => {
      // Log Cus_code for each customer

      // Fetch product sales data for each customer
      http.put(`/productsales/${customer.Cus_code}`)
        .then((response) => {
          // Update the products state with the fetched data
          setproducts(response.data);
        })
        .catch((error) => {
          console.error('Error fetching product sales data:', error);
        });
        http.put(`/customerdetails/${customer.Cus_code}`)
        .then((response) => {
          // Update the products state with the fetched data
          setrecieved(response.data);
        })
        .catch((error) => {
          console.error('Error fetching product sales data:', error);
        });
    });
  }, [customer]);
  return (
    <div className="container pt-5">
      {customer.map((customer) => (
            <div key={customer.id}>
      <div className="row pt-5">
        <div className="col-md-8 customer-details">

          <h2>Customer Details</h2>
          <div className='row'>
          <div className='col-md-4'>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <label id="name" className="form-control">
              {customer.Customer_Name}
            </label>
          </div>
          </div>
          <div className='col-md-4'>
          <div className="form-group">
            <label htmlFor="name">Father Name:</label>
            <label id="name" className="form-control">
              {customer.Father_Name}
            </label>
          </div>
          </div>
          </div>
          <div className='row'>
          <div className='col-md-4'>
          <div className="form-group">
            <label htmlFor="name">Email:</label>
            <label id="name" className="form-control">
              {customer.Email}
            </label>
          </div>
          </div>
          <div className='col-md-4'>
          <div className="form-group">
            <label htmlFor="name">CNIC:</label>
            <label id="name" className="form-control">
              {customer.Cnic}
            </label>
          </div>
          </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <label id="email" className="form-control">
              {customer.Email}
            </label>
          </div>
          {/* Add more labels for other customer details */}
        </div>
        {/* <div className='col-md-2'></div> */}
        <div className="col-md-4 customer-picture pl-5">
          <img
            src={customer.imagePaths}
            alt="Customer"
            className="img-fluid rounded-circle"
          />
        </div>
      </div>
      </div>
      ))}
      <div className='row'>
      <div className="tab-buttons">
        <button onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active' : ''}>
          Poduct Detail
        </button>
        <button onClick={() => handleTabClick(2)} className={activeTab === 2 ? 'active' : ''}>
          Customer Details
        </button>
        <button onClick={() => handleTabClick(3)} className={activeTab === 3 ? 'active' : ''}>
          Tab 3
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 1 && <div> 
      <h1>sale List</h1>
        <Table striped bordered hover>
        <thead>
          <tr>
            
            <th>Item Name</th>
            <th>Total Amount</th>
            <th>Discount</th>
            <th>Net Amount</th>
           
          </tr>
        </thead>
        <tbody>
          {products.map((sale) => (
            
              <tr>
                
                <td>{sale.title}</td>
                <td>{sale.totalamount}</td>
                <td>{sale.discount}</td>
                <td>{sale.netamount}</td>
                
                
              </tr>
          ))}
        </tbody>
      </Table></div>}
        {activeTab === 2 && <div> <h1>Customer Details</h1>
        <Table striped bordered hover>
        <thead>
          <tr>
            
            <th>Customer Name</th>
            <th>Payment Type</th>
            <th>Recived Amount</th>
           
          </tr>
        </thead>
        <tbody>
          {recieved.map((sale) => (
            
              <tr>
                
                <td>{sale.customername}</td>
                <td>{sale.Payment_Type}</td>
                <td>{sale.recivedamount}</td>
                
                
                
              </tr>
          ))}
        </tbody>
      </Table></div>}
        {activeTab === 3 && <div>Content for Tab 3</div>}
      </div>
       
      </div>
    </div>

  );
}

export default CustomerForm;
