import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import AuthUser from './Authuser';


const Sale = () => {
  const location = useLocation();
  const moment = require('moment');
  const {http} = AuthUser();
  const [customer, setCustomer] = useState([]);
  const [nominee, setNominee] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [invoice , setInvoice] = useState([]);
  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);

  const [customerData, setCustomerData] = useState({
    customername: null,
    customercode:'',
    nomineename: '',
    nomineecode:'',
    invoiceno: invoice ,
    totalamount: 0,
    discount: 0,
    netamount:0,
    recivedamount:0,
    balanceamount:0,
  });

  useEffect(() => {
    // Fetch data for an existing sale
  
    if (location.state && location.state.sale) {

      http
      .put(`/productsaled/${location.state.sale}`) // Use 'get' for fetching data
      .then((response) => {
        setSelectedProducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      http
        .put(`/customerdetails/${location.state.sale}`)
        .then((response) => {
           // Log the entire response to inspect its structure
          
          if (response && response.data && response.data.length > 0) {
            // Assuming you have an array of data and you want the first item
            const firstSaleData = response.data[0];
  
            setCustomerData({
              customername: firstSaleData.customername,
              customercode: firstSaleData.customercode,
              invoiceno: firstSaleData.invoiceno,
              nomineename : firstSaleData.nomineename,
              nomineecode : firstSaleData.nomineecode,
              totalamount : firstSaleData.totalamount,
              discount : firstSaleData.discount,
              netamount :firstSaleData.netamount,
              recivedamount : firstSaleData.recivedamount,
              balanceamount : firstSaleData.balanceamount,
              // Assign other fields
            });
            setInvoice(firstSaleData.invoiceno);
            const updatedAtMoment = moment(firstSaleData.updated_at);

// Format the date as a string
            const formattedDate = updatedAtMoment.format("MM/DD/YYYY h:mm a");
            const dateObject = new Date(formattedDate); // Convert the formatted string to a Date object
            handleDateChange(dateObject);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
        toggleSaveButtonVisibility();
    }
  }, []);
   // Remove the dependency array
  
  
 
  const [totalAmount, setTotalAmount] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleAddInvoice = () => {
    http
      .put('/customers') // Use 'get' for fetching data
      .then((response) => {
        setCustomer(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      if(!location.data ){

      
      http.post('/invoices')
        .then((response) => {
            const updatedInvoiceNumber = response.data.invoice_number;
            setInvoice(updatedInvoiceNumber);
        })
        .catch((error) => {
            console.error(error);
        });
          // Set the initial value for selectedDate to the current date
          setSelectedDate(new Date());
      }
      http
      .get('/products') // Replace with the actual API endpoint
      .then((response) => {
        setProducts(response.data.product);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      http
      .put('/nominees') // Replace with the actual API endpoint
      .then((response) => {
        setNominee(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      }); 
      toggleSaveButtonVisibility();
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({
      ...customerData,
      [name]: value,
    });
  };

  const handleProjectNameChange = (event, newValue) => {
    setCustomerData({
      ...customerData,
      customername: newValue.Customer_Name,
      customercode: newValue.Cus_code,
    });
    
  };

  const handleNomineeNameChange = (event, newValue) => {
    setCustomerData({
      ...customerData,
      nomineename: newValue.Nominee_Name,
      nomineecode: newValue.Nom_code,
    });
  };
  const toggleSaveButtonVisibility = () => {
    setIsSaveButtonVisible(!isSaveButtonVisible);
  };

  const handleProductNameChange = (selectedProductName) => {
    if (selectedProductName !== null && selectedProductName !== undefined) {
      // Access variable.value safely
    
    const selectedProduct = products.find((product) => product.unique_code === selectedProductName.value);
  
    if (selectedProduct) {

      const productToAdd = {
        customername: customerData.customername,
        nomineename: customerData.nomineename,
        invoiceno: invoice,
        product: customerData.product,
        totalamount: customerData.totalamount,
        discount: customerData.discount,
        netamount:customerData/netAmount,
        recivedamount:customerData.recivedamount,
        balanceamount:customerData.balanceamount,
        title: selectedProduct.title,
        productprice: selectedProduct.productprice,
        productsize: selectedProduct.productsize,
        unique_code: selectedProduct.unique_code, // Include any other properties you need
        quantity: 1,
        amount: selectedProduct.productprice, // Set a default quantity
      };

      setSelectedProducts([...selectedProducts, productToAdd]);
    recalculateTotalAmount([...selectedProducts, productToAdd]);
      // Pass the updated selectedProducts
      
      
    }
  }
  };
  
  const handleQuantityChange = (index, quantity) => {
    
    const updatedProducts = [...selectedProducts];
    const productToEdit = updatedProducts[index];
    if (productToEdit) {
      // Create a new object by spreading the properties of the product to edit
      const updatedProduct = { ...productToEdit };
      updatedProduct.quantity = quantity;
      updatedProduct.amount = quantity * updatedProduct.productprice;
  
      updatedProducts[index] = updatedProduct; // Update the product at the specified index
      setSelectedProducts(updatedProducts);
      recalculateTotalAmount(updatedProducts);
    }
  };

  const handlePriceChange = (index, price) => {
    const updatedProducts = [...selectedProducts];
  const productToEdit = updatedProducts[index];
  
  if (productToEdit) {
    // Create a new object by spreading the properties of the product to edit
    const updatedProduct = { ...productToEdit };
    updatedProduct.productprice = price;
    updatedProduct.amount = price * updatedProduct.quantity;

    updatedProducts[index] = updatedProduct; // Update the product at the specified index
    setSelectedProducts(updatedProducts);
    recalculateTotalAmount(updatedProducts);

   
  }
  };
  const handleSizeChange = (index, size) => {
    const updatedProducts = [...selectedProducts];

    const productToEdit = updatedProducts[index];

  if (productToEdit) {
    // Create a new object by spreading the properties of the product to edit
    const updatedProduct = { ...productToEdit };
    updatedProduct.productsize = size;

    updatedProducts[index] = updatedProduct; // Update the product at the specified index
    setSelectedProducts(updatedProducts);
    
  }
    
  };
  const recalculateTotalAmount = (products) => {
    const total = products.reduce((acc, product) => {
      return acc + (product.productprice || 0) * (product.quantity || 1);
    }, 0);
    setTotalAmount(total);
    const newNetAmount = total - customerData.discount;
  const newBalanceAmount = newNetAmount - customerData.recivedamount;

  setTotalAmount(total);
  setCustomerData({
    ...customerData,
    totalamount: total,
    netamount: newNetAmount,
    balanceamount: newBalanceAmount,
  });
};
  const handleDiscountChange = (e) => {
    const discount = parseFloat(e.target.value); // Parse the discount as a number
    const newTotalAmount = totalAmount - discount; // Subtract the discount from the total amount
    setNetAmount(newTotalAmount);
    setCustomerData({
      ...customerData,
      discount: discount, // Update the discount in the customerData
      netamount: newTotalAmount, // Update the total amount in the customerData
    });
  };
  const handleRecivedChange = (e) => {
    const recieved = parseFloat(e.target.value); // Parse the discount as a number
    const balance = netAmount - recieved;
     // Subtract the discount from the total amount
    setCustomerData({
      ...customerData,
      recivedamount: recieved, // Update the discount in the customerData
      balanceamount: balance, // Update the total amount in the customerData
    });
  };
  
  const handleSubmit = async(e) => {

    e.preventDefault(); // Prevent the default form submission behavior
    
  const productToAddArray = [];

for (let i = 0; i < selectedProducts.length; i++) {
  const productToAdd = {
    customername: customerData.customername,
    nomineename: customerData.nomineename,
    invoiceno: invoice,
    Payment_Type:"Sale",
    totalamount: customerData.totalamount,
    discount: customerData.discount,
    netamount: customerData.netamount,
    recivedamount: customerData.recivedamount,
    balanceamount: customerData.balanceamount,
    nomineecode:customerData.nomineecode,
    customercode:customerData.customercode,
    title: selectedProducts[i].title,
    productprice: selectedProducts[i].productprice,
    productsize: selectedProducts[i].productsize,
    unique_code: selectedProducts[i].unique_code,
    quantity: selectedProducts[i].quantity,
    amount: selectedProducts[i].productprice,
  };

  productToAddArray.push(productToAdd);
}
const productToAdd = {
  customername: customerData.customername,
  nomineename: customerData.nomineename,
  invoiceno: invoice,
  Payment_Type:"Sale",
  totalamount: customerData.totalamount,
  discount: customerData.discount,
  netamount: customerData.netamount,
  recivedamount: customerData.recivedamount,
  balanceamount: customerData.balanceamount,
  nomineecode:customerData.nomineecode,
  customercode:customerData.customercode,
};
  if(location.state){

    try {
  const [response, responses] = await Promise.all([
    http.post('/update-sale', productToAddArray, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
    http.post('/update_history', productToAdd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  ]);
  
  if (responses.data.message === 'Sale updated successfully' && response.data.message === 'Records updated successfully') {
    // Both requests were successful
    swal("Good job!", "Sale Update Successfully!", "success");
    navigate(`/Admin/showsale`);
  } else {
    // Handle the case where one or both requests failed
    swal("ERROR!", "An error occurred during the request.", "error");
  }
} catch (error) {
  // Handle any other errors that may occur during the requests
  console.error('Error saving product:', error);
}
  }
  else{
try {

  const [responses, response] = await Promise.all([
   
    http.post('/cus_history', productToAdd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),

   http.post('/save-sale', productToAddArray, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
]);
  

  if (response.data.message === 'Sale saved successfully' && responses.data.message === 'Payment_IN  saved successfully') {
    // Reset the form or show a success message
    swal("Good job!", "Sale Save Successfully!", "success");
  navigate(`/Admin/showsale`);
  }
  else{
   // console.error('Error:', response.data);
    
  swal("ERROR!",response.data , "error");
  }

} catch (error) {
  // Handle any errors here
  console.error('Error saving product:', error);
}
  }
  };


  return (
    <div className="container-fluid mt-5">
      <div className="pt-5">
        <h2 className="text-center">SALE INVOICE</h2>
      </div>
      <div className="row mt-5">
        {/* Left Column */}
        <div className="col-md-4">
          <div className="left-column">
            <div className='row'>
              <div className='col-md-8'>
                <div className="form-group">
                <Autocomplete
                id="customername"
                options={customer || []} // Ensure 'customer' is not undefined
                getOptionLabel={(option) => (option && option.Customer_Name) || customerData.customername} // Check for 'option' existence
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={customerData.customername || 'Search Customer by Name'}
                    style={{ width: '215px', height: '20px' }}
                  />
                )}
                onChange={handleProjectNameChange}
                getOptionSelected={(option, value) => (option.Customer_Name === value.Customer_Name) && (option.Cus_code === value.Cus_code) }
              />



                </div>
                <div className="form-group pt-4">
                <Autocomplete
                id="nomineename"
                options={nominee || []}
                getOptionLabel={(option) => (option.Nominee_Name) || customerData.nomineename}
                renderInput={(params) => (
                  <TextField {...params}
                  label={customerData.nomineename || 'Search Nominee by Name'}
                   style={{ width: '215px', height: '20px' }} />
                )}
                onChange={handleNomineeNameChange}
                getOptionSelected={(option, value) => option.Nominee_Name === value.Nominee_Name}
              />

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Middle Column */}
        <div className="col-md-5">
          <div className='row'>
            <div className='col-md-8'>
              <div className="form-group">
              <Autocomplete
                    id="nomineename"
                    options={nominee.map((product) => product.Nominee_Name)}
                    getOptionLabel={(option) => option || ''}
                    renderInput={(params) => (
                      <TextField {...params} label="Search Nominee by Name"  style={{ width: '215px', height: '20px' }} />
                    )}
                    value={customerData.nomineename || null}
                    onChange={handleNomineeNameChange}
                    getOptionSelected={(option, value) => option === value}
                  />
              </div>
              <div className="form-group pt-4">
                
              <Autocomplete
  id="product"
  options={products.map((product) => ({
    label: product.title,
    value: product.unique_code ,
  }))}
  getOptionLabel={(option) => option.label || ''}

  renderOption={(option) => (
    <div>
      {option.label} - {option.value}
    </div>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      id="itemname"
      label="Select Product by Name"
      style={{ width: '210px', height: '20px' }}
    />
  )}
  value={customerData.product || ""}
  onChange={(event, newValue) => handleProductNameChange(newValue)}
  getOptionSelected={(option, value) => option.value === value.value}

/>



              </div>
            </div>
          </div>
        </div>

        {/* Right Column (in the right corner) */}
        <div className="col-md-3">
          <div className="right-column">
          <div className="row">
              <div className="col-md-4">

                <div className="form-group">
                  <label htmlFor="invoiceno" className="form-label pt-2">
                    Invoice N.O
                  </label>
                </div>
              </div>
              <div className="col-md-7">
              <span className="input-group-text">
              <label htmlFor="invoiceno" className="form-label pt-2">
                    {invoice || customerData.invoiceno}
                  </label>
                </span>
              </div>
              
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="invoiceno" className="form-label pt-2">
                    Date
                  </label>
                </div>
              </div>
              <div className="col-md-7">
                <DatePicker
                  selected={selectedDate}
                  
                  onChange={handleDateChange}
                  showTimeSelect // Enable time selection
                  timeFormat="HH:mm" // Set the time format to 24-hour format (e.g., 13:30)
                  dateFormat="dd/MM/yyyy h:mm a"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <table className="table table-bordered">
  <thead>
    <tr>
      <th className="col-md-3" >Product Name</th>
      <th className="col-md-2" >Price</th>
      <th className="col-md-2" >Size</th>
      <th className="col-md-2">Quantity</th>
      <th className="col-md-3" >Amount</th>
    </tr>
  </thead>
  <tbody>
    {selectedProducts.map((product, index) => (
      <tr key={index} id={'sale'}>
        <td>{product.title}</td>
        <td>
          <input
            type="number"

            value={product.productprice || 0 }
            
            onChange={(e) => handlePriceChange(index, e.target.value)}
          />
        </td>
        <td>
          <input
            type="number"
            value={product.productsize || 0}
            onChange={(e) => handleSizeChange(index, e.target.value)}
          />
        </td>
        <td>
          <input
            type="number"
            value={product.quantity || 1}
            onChange={(e) => handleQuantityChange(index, e.target.value)}
          />
        </td>
        <td>
          {(product.productprice || 0) * (product.quantity || 1) }</td>
      </tr>
    ))}
  </tbody>
</table>
      <div className='row'>
        <div className='col-md-3'>
        <div className="left-column">
          </div>
        </div>
        <div className='col-md-5'></div>

      <div className="col-md-4"> 
  <div className="right-column">
    <div className="row ">
      <div className="col-md-5 ">
        <div className="form-group ">
          <label htmlFor="totalamount" className=" right-align form-label pt-2 ">
            Total Amount
          </label>
        </div>
      </div>
      <div className="col-md-6">
          <input
        type="text"
        className="form-control"
        id="totalamount"
        name="totalamount"
        value={customerData.totalamount || ''} // Set the value to customerData.totalamount
        readOnly
      />
      </div>
    </div>
    
    <div className="row ">
      <div className="col-md-5 ">
        <div className="form-group ">
          <label htmlFor="discount" className=" right-align form-label pt-2 ">
            Discount
          </label>
        </div>
      </div>
      <div className="col-md-6">
        <input
          type="text"
          className="form-control"
          id="discount"
          name="discount"
          value={customerData.discount || 0}
          onChange={handleDiscountChange}
          required
        />
      </div>
    </div>
    <div className="row ">
      <div className="col-md-5 ">
        <div className="form-group ">
          <label htmlFor="netamount" className=" right-align form-label pt-2 ">
            Net Amount
          </label>
        </div>
      </div>
      <div className="col-md-6">
        <input
          type="text"
          className="form-control"
          id="netamount"
          name="netamount"
          value={customerData.netamount || customerData.totalamount}
          onChange={handleInputChange}
          required
        />
      </div>
    </div>
    <div className="row ">
      <div className="col-md-5 ">
        <div className="form-group ">
          <label htmlFor="recivedamount" className=" right-align form-label pt-2 ">
            Recived Amount
          </label>
        </div>
      </div>
      <div className="col-md-6">
        <input
          type="text"
          className="form-control"
          id="recivedamount"
          name="recivedamount"
          value={customerData.recivedamount || 0}
          onChange={handleRecivedChange}
          required
        />
      </div>
    </div>

    <div className="row ">
      <div className="col-md-5 ">
        <div className="form-group ">
          <label htmlFor="balanceamount" className=" right-align form-label pt-2 ">
            Balance Amount
          </label>
        </div>
      </div>
      <div className="col-md-6">
        <input
          type="text"
          className="form-control"
          id="balanceamount"
          name="balanceamount"
          value={customerData.balanceamount }
          onChange={handleInputChange}
          required
          readOnly
        />
      </div>
    </div>

   
  </div>
</div>


      </div>
      <div>
      {isSaveButtonVisible ? (
                // Render the "Save" button when isSaveButtonVisible is true
                <button
                  id="save-button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  {'Save Sale'}
                </button>
              ) : (
                // Render the "Add" button when isSaveButtonVisible is false
                <button
                  id="add-invoice"
                  className="btn btn-primary"
                  onClick={handleAddInvoice}
                >
                  Add
                </button>
              )}
        </div>
    </div>
  );
};

export default Sale;
