import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import AuthUser from './Authuser';

const CustomerForm = () => {
  const {http} = AuthUser();
  const [thumbnailImages, setThumbnailImages] = useState([]);
  const [CustomersavImages, setThumbnailsavImages] = useState([]);
  const [phoneValid, setPhoneValid] = useState(true);
  const navigate = useNavigate();
  // const [showSuccess, setShowSuccess] = useState(false);
  const [CustomerData, setFormData] = useState({
    customerName: '',
    fatherName: '',
    cnic: '',
    email: '',
    phone: '',
    nationality: '',
    address: '',
    // Add more fields as needed
  });
  const handleFileInputChange = (e, imageType) => {
    const files = e.target.files;
    if (files.length > 0) {
      // Filter valid images
      const validImages = Array.from(files).filter((file) =>
        ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(file.type)
      );
  
      if (imageType === 'thumbnail') {
        setThumbnailsavImages(e.target.files);
        setThumbnailImages(validImages.map((file) => URL.createObjectURL(file)));
      } else {
        alert('Invalid image type');
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...CustomerData, [name]: value });
    
    
  };
  const validatePhoneNumber = (phoneNumber) => {
    if (phoneNumber.startsWith('+') || phoneNumber.match(/^\d{1,4}$/)) {
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
      const isValid = parsedPhoneNumber ? parsedPhoneNumber.isValid() : true;
      setPhoneValid(isValid);
    } else {
      // If no country code is provided, consider it valid (or you can apply different logic)
      setPhoneValid(false);
    }
  };

  const handleBlur = () => {
    // Validate the phone number when the input field loses focus
    validatePhoneNumber(CustomerData.phone);
  };

  const handleSubmit= async (e) => {
    e.preventDefault();
    
    // Handle form submission here (e.g., send data to the server)
    if (phoneValid) {
      // Phone number is valid, proceed with form submission
      
    const formData = new FormData();
   
    formData.append('customerName', CustomerData.customerName);
formData.append('fatherName', CustomerData.fatherName);
formData.append('Cnic', CustomerData.cnic); // Update 'projecttype' to 'type'
formData.append('email', CustomerData.email);
formData.append('phone', CustomerData.phone);
formData.append('nationality', CustomerData.nationality);
formData.append('address', CustomerData.address);

for (let i = 0; i < CustomersavImages.length; i++) {
  formData.append(`Customer_Image[]`, CustomersavImages[i]);
  console.log(CustomersavImages[i]);
}

 

 // Append the selected thumbnail images to the form data

  
   try {
    const password = "Pime123";
    const customer = "Customer";
    

     

     http.post('/register',{email:CustomerData.email,password:password,name:CustomerData.customerName ,usertype:customer}, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const response = await http.post('/save-customer',formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  
     if (response.data.message === 'Customer saved successfully') {
      swal("Good job!", "Customer Save Successfully!", "success");
      navigate(`/Admin/showCustomer`);
     }
     else{
       //console.error('Error:', response.data);
       swal("ERROR!",response.data , "error");
       
     }
    
   } catch (error) {
     // Handle any errors here
     console.error('Error saving product:', error);
   }
    } else {
      // Phone number is invalid, show an error message
      alert('Invalid phone number. Please correct it before submitting.');
    }
  };

  return (
    <>
    <div className="container mt-5">
      
          <form onSubmit={handleSubmit}>
          
            <h2 className='mt-5 text-center pt-5'>ADD CUSTOMER DETAILS</h2>
            <div className='card m-1 mt-5'>
              <h5>Customer Information</h5>

            <div className='card-body m-1'>
            <div className="row">
        <div className="col-sm-9">
            <div className="form-group">
              <label htmlFor="firstName">Customer Name</label>
              <input
                type="text"
                className="form-control"
                id="customerName"
                name="customerName"
                value={CustomerData.customerName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Father Name</label>
              <input
                type="text"
                className="form-control"
                id="fatherName"
                name="fatherName"
                value={CustomerData.fatherName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="cnic">CNIC</label>
              <input
                type="text"
                className="form-control"
                id="cnic"
                name="cnic"
                value={CustomerData.cnic}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="cnic">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={CustomerData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className='row'>
            <div className='col'>
            <div className="form-group">
        <label htmlFor="phone">Phone N.O</label>
        <input
          type="tel"
          className={`form-control ${phoneValid ? '' : 'is-invalid'}`}
          id="phone"
          name="phone"
          value={CustomerData.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {/* Display a validation feedback message */}
        {!phoneValid && (
          <div className="invalid-feedback">Invalid phone number.</div>
        )}
      </div>
            </div>

            <div className='col'>
            <div className="form-group">
              <label htmlFor="nationality">Nationality</label>
              <input
                type="text"
                className="form-control"
                id="nationality"
                name="nationality"
                value={CustomerData.nationality}
                onChange={handleChange}
                required
              />
            </div>
            </div>
            </div>
            <div className="form-group">
              <label htmlFor="cnic">Address</label>
              <textarea
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={CustomerData.address}
                onChange={handleChange}
                required
              />
            </div>
            </div>
            <div className='col mt-2'>

            <div className="input-group">
        <input
          type="file"
          className="form-control "
          id="fileInput2"
          style={{ display: 'none' }}
          onChange={(e) => handleFileInputChange(e, 'thumbnail')}
        />
        <label
  htmlFor="fileInput2"
  className="form-control border border-primary border-5 cursor-pointer d-flex justify-content-center align-items-center"
  style={{ width: '140px', height: '220px'}}>
{thumbnailImages && thumbnailImages.length > 0 ? (
        <img src={thumbnailImages[0]} alt="Thumbnail Preview" className="img-cus" />
      ) : (
        <i className="fa fa-cloud-upload-alt fa-4x ml-1"></i>
      )}
</label>



      </div>
            </div>
            </div>
            
           
            {/* Add more form fields here */}
            <button type="submit" className="btn btn-primary mt-3">
              Submit
            </button>
            
        
            </div>
            </div>
      </form>
    </div>
    </>
  );
};

export default CustomerForm;
