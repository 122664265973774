import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AuthUser from './Authuser';


const CustomerForm = (props) => {
    const {http,token} = AuthUser();
    const { show, handleClose, customer , updateCustomer} = props;

    const [thumbnailImages, setThumbnailImages] = useState([]);
    const [CustomersavImages, setThumbnailsavImages] = useState([]);
    const [phoneValid, setPhoneValid] = useState(true);

    const [CustomerData, setFormData] = useState({
      customerName: '',
      fatherName: '',
      cnic: '',
      email: '',
      phone: '',
      nationality: '',
      address: '',
      // Add more fields as needed
    });
    
     
    useEffect(() => {
        // Set the form data to the selected customer's data when it changes
        if (customer) {
          setFormData({
            customerName: customer.Customer_Name || '',
            fatherName: customer.Father_Name || '',
            cnic: customer.Cnic || '',
            email: customer.Email || '',
            phone: customer.Phone || '',
            nationality: customer.Nationality || '',
            address: customer.Address || '',
            
          });
          
          thumbnailImages[0]= customer.imagePaths;
        }
      }, [customer]);

      const handleFileInputChange = (e, imageType) => {
        const files = e.target.files;
        if (files.length > 0) {
          // Filter valid images
          const validImages = Array.from(files).filter((file) =>
            ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(file.type)
          );
      
          if (imageType === 'thumbnail') {
            setThumbnailsavImages(e.target.files);
            setThumbnailImages(validImages.map((file) => URL.createObjectURL(file)));
          } else {
            alert('Invalid image type');
          }
        }
      };
      
    
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...CustomerData, [name]: value });
      
      
    };
    const validatePhoneNumber = (phoneNumber) => {
      if (phoneNumber.startsWith('+') || phoneNumber.match(/^\d{1,4}$/)) {
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
        const isValid = parsedPhoneNumber ? parsedPhoneNumber.isValid() : true;
        setPhoneValid(isValid);
      } else {
        // If no country code is provided, consider it valid (or you can apply different logic)
        setPhoneValid(false);
      }
    };
    const handlePromptClicks = () => {
    // Display a prompt dialog
    window.prompt('Data Save Sucessfully');
  }
  
    const handleBlur = () => {
      // Validate the phone number when the input field loses focus
      validatePhoneNumber(CustomerData.phone);
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Handle form submission here (e.g., send data to the server)
        if (phoneValid) {
          // Phone number is valid, proceed with form submission
      
          const formData = new FormData();
      
          formData.append('customerName', CustomerData.customerName);
          formData.append('fatherName', CustomerData.fatherName);
          formData.append('Cnic', CustomerData.cnic);
          formData.append('email', CustomerData.email);
          formData.append('phone', CustomerData.phone);
          formData.append('nationality', CustomerData.nationality);
          formData.append('address', CustomerData.address);
      
          // Append the selected thumbnail images to the form data
          for (let i = 0; i < CustomersavImages.length; i++) {
            formData.append(`Customer_Image[]`, CustomersavImages[i]);
        }
            
            const password = "Pime123";
            const customertype = "Customer";
      
          // After handling the form submission in your handleSubmit function
          

  const requestData = {
    email: CustomerData.email,
    password: password,
    name: CustomerData.customerName,
    usertype: customertype,
  };

  
    
        

          try {
            const response = await http.post(`/update-customer/${customer.Cus_code}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
          
            // Assuming your API returns data including 'customer' with updated data
            const updatedCustomer = response.data;

            http.post(`/updatecustomer/${CustomerData.email}`, requestData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
              },
            });
            // Update the 'customers' state with the updated customer
            
            updateCustomer((prevCustomers) => {
              // Find the index of the updated customer in the existing state
              const updatedIndex = prevCustomers.findIndex((c) => c.Cus_code === updatedCustomer.Cus_code );
                
              // Create a new array with the updated customer replaced
              const updatedCustomers = [...prevCustomers];
              updatedCustomers[updatedIndex] = updatedCustomer;
          
              return updatedCustomers;
             
            });
            handlePromptClicks();
            handleClose();
          } catch (error) {
            console.error('Error fetching data:', error);
          }
          
          
        } else {
          // Phone number is invalid, show an error message
          alert('Invalid phone number. Please correct it before submitting.');
        }
      };
      

  return (
    <>
   
   <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>ADD CUSTOMER DETAILS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
      <form onSubmit={handleSubmit}>
        <div className='card m-1 mt-5'>
          <h5>Customer Information</h5>
        
        <div className='card-body m-1'>
        <div className="row">
    <div className="col-sm-9">
        <div className="form-group">
          <label htmlFor="firstName">Customer Name</label>
          <input
            type="text"
            className="form-control"
            id="customerName"
            name="customerName"
            value={CustomerData.customerName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Father Name</label>
          <input
            type="text"
            className="form-control"
            id="fatherName"
            name="fatherName"
            value={CustomerData.fatherName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="cnic">CNIC</label>
          <input
            type="text"
            className="form-control"
            id="cnic"
            name="cnic"
            value={CustomerData.cnic}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="cnic">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={CustomerData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className='row'>
        <div className='col'>
        <div className="form-group">
    <label htmlFor="phone">Phone N.O</label>
    <input
      type="tel"
      className={`form-control ${phoneValid ? '' : 'is-invalid'}`}
      id="phone"
      name="phone"
      value={CustomerData.phone}
      onChange={handleChange}
      onBlur={handleBlur}
      required
    />
    {/* Display a validation feedback message */}
    {!phoneValid && (
      <div className="invalid-feedback">Invalid phone number.</div>
    )}
  </div>
        </div>

        <div className='col'>
        <div className="form-group">
          <label htmlFor="nationality">Nationality</label>
          <input
            type="text"
            className="form-control"
            id="nationality"
            name="nationality"
            value={CustomerData.nationality}
            onChange={handleChange}
            required
          />
        </div>
        </div>
        </div>
        <div className="form-group">
          <label htmlFor="cnic">Address</label>
          <textarea
            type="text"
            className="form-control"
            id="address"
            name="address"
            value={CustomerData.address}
            onChange={handleChange}
            required
          />
        </div>
        </div>
        <div className='col mt-2'>

        <div className="input-group">
  <input
    type="file"
    className="form-control"
    id="fileInput2"
    style={{ display: 'none' }}
    onChange={(e) => handleFileInputChange(e, 'thumbnail')}
  />
  <label
    htmlFor="fileInput2"
    className="form-control border border-primary border-5 cursor-pointer d-flex justify-content-center align-items-center"
    style={{ width: '140px', height: '220px' }}
  >
    {thumbnailImages && thumbnailImages.length > 0 ? (
      <img src={thumbnailImages[0]} alt="Thumbnail Preview" className="img-cus" />
    ) : (
      <i className="fa fa-cloud-upload-alt fa-4x ml-1"></i>
    )}
  </label>
</div>

        </div>
        </div>
        
       
        {/* Add more form fields here */}
        <button type="submit" className="btn btn-primary mt-3">
        Save Changes
        </button>
        <Button variant="secondary" onClick={() => { handleClose();}}>
          Close
        </Button>
    
        </div>
        </div>
  </form>
</Modal.Body>

      <Modal.Footer>
      
        
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default CustomerForm;
