import React, { useState, useEffect } from 'react';
import AuthUser from './Authuser';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
 import swal from 'sweetalert';
 import Updateproduct from './UpdateProduct';


function ProductList() {
  const {http} = AuthUser();
    const [products, setProducts] = useState([]);
  const [selectedproduct, setSelectedproduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  



useEffect(() => {
    try {
      // Make an http GET request to fetch nominee data
      http.get('/products')
        .then((response) => {
            setProducts(response.data.product);
          console.log(products);
        })
        .catch((error) => {
          console.error('Error fetching nominee data:', error);
        });
    } catch (error) {
      console.error('An error occurred while fetching nominee data:', error);
    }
  }, []);
  

  const handleEditClick = (product) => {
  //  Handle edit action here
  
    setSelectedproduct(product);
    setShowModal(true);
  };
  const updateproduct = (updatedproducts) => {
    setProducts(updatedproducts);
    
  };
  
  const handleDeleteClick = async (cusid) => {

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {

      if (willDelete) {
        try {
           http.delete(`/delete-product/${cusid}`); // Replace with your API endpoint
          // Remove the deleted item from the state
          updateproduct((products) => products.filter((item) => item.unique_code !== cusid));
        } catch (error) {
          console.error('Error deleting data:', error);
        }
        swal("Poof! Your product file has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your imaginary file is safe!");
      }
    });
    
  }

  return (
    <div>
      <h1>product List</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Project</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td>{product.id}</td>
              <td>{product.title}</td>
              <td>{product.projectname}</td>
              <td>
                <Button variant="primary" onClick={() => handleEditClick(product)}>
                  Edit
                </Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteClick(product.unique_code)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showModal && (
        <Updateproduct
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setSelectedproduct(null);
          }}
          product={selectedproduct}
          updateproduct={updateproduct}
        />
      )}
      
    </div>
  );
}

export default ProductList;
