import React, { useState, useEffect } from 'react';
import AuthUser from './Authuser';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
 import swal from 'sweetalert';
 import { useNavigate } from 'react-router-dom';


function SaleList() {
    const [Sales, setsales] = useState([]);
  // const [selectedsale, setSelectedsale] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  const {http} = AuthUser();
  const Navigate = useNavigate();



useEffect(() => {
    try {
      // Make an http GET request to fetch nominee data
      http.get('/sales')
        .then((response) => {
            setsales(response.data);
          
        })
        .catch((error) => {
          console.error('Error fetching nominee data:', error);
        });
    } catch (error) {
      console.error('An error occurred while fetching nominee data:', error);
    }
  }, []);
  

  const handleEditClick = (sale) => {
  //  Handle edit action here
  
    // setSelectedsale(sale);
    // setShowModal(true);
    const update = "Update";
    
    Navigate(`/Admin/sale`,{state:{update,sale}});


  };
  const updatesale = (updatedsales) => {
    setsales(updatedsales);
    
  };
  
  const handleDeleteClick = async (cusid) => {

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {

      if (willDelete) {
        try {
           http.delete(`/delete-sale/${cusid}`); // Replace with your API endpoint
          // Remove the deleted item from the state
          updatesale((sales) => sales.filter((item) => item.unique_code !== cusid));
        } catch (error) {
          console.error('Error deleting data:', error);
        }
        swal("Poof! Your sale file has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your imaginary file is safe!");
      }
    });
    
  }
  

  return (
    <div>
      <h1>sale List</h1>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Invoice</th>
          <th>Customer Name</th>
          <th>Total Amount</th>
          <th>Discount</th>
          <th>Received Amount</th>
          <th>Balance</th>
        </tr>
      </thead>
      <tbody>
        {Sales.map((sale) => (
          
            <tr>
              <td>{sale.invoiceno}</td>
              <td>{sale.customername}</td>
              <td>{sale.totalamount}</td>
              <td>{sale.discount}</td>
              <td>{sale.recivedamount}</td>
              <td>{sale.balanceamount}</td>
              <td><Button variant="primary" onClick={() => handleEditClick(sale.invoiceno)}>
                  Edit
                </Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteClick(sale.invoiceno)}>
                  Delete
                </Button></td>
            </tr>
        ))}
      </tbody>
    </Table>

      
    </div>
    
    
  );
}

export default SaleList;
