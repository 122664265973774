import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

export default function MainNavbar(props) {
  return (
    <nav className={`navbar navbar-expand-lg navbar-${props.swithchmode} bg-${props.swithchmode} fixed-top navbar-light bg-dark border-2`}>
      <div className="container-fluid">
        <Link className="navbar-brand fw-bold text-light" to="/admin/">
          {props.titles}
        </Link>
        <button
          className="navbar-toggler text-light"
          
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon bg-light"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active text-light" aria-current="page" to="/admin/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active text-light" aria-current="page" to="/Admin/customerverification">
                Customer Verification
              </Link>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="" className="text-light" id="dropdown-basic">
                  Sale
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/Admin/sale">Sale</Dropdown.Item>
                  <Dropdown.Item href="/Admin/showsale">Show Sale</Dropdown.Item>
                  <Dropdown.Item href="/Admin/paymentin">Payment_IN</Dropdown.Item>
                  <Dropdown.Item href="/Admin/showpaymentin">Show Payment_IN</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="" className="text-light" id="dropdown-basic">
                  Nominee
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/Admin/addNominee">Nominee</Dropdown.Item>
                  <Dropdown.Item href="/Admin/shownominee">Show Nominee</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-light" to="/Admin/register">
                {props.about}
              </Link>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="" className="text-light" id="dropdown-basic">
                  Customer
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/Admin/addcustomer">Customer</Dropdown.Item>
                  <Dropdown.Item href="/Admin/showCustomer">Show Customer</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="" className="text-light" id="dropdown-basic">
                  Product
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/Admin/product">{props.product}</Dropdown.Item>
                  <Dropdown.Item href="/Admin/showproduct">Show Product</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-light" to="/Admin/project">
                Project
              </Link>
            </li>
          </ul>
          <button className="btn btn-outline-primary text-light" onClick={props.logout}>
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
}

MainNavbar.propTypes = {
  title: PropTypes.string.isRequired,
  about: PropTypes.string,
};

MainNavbar.defaultProps = {
  title: 'Set Title',
  about: 'Set about',
};
