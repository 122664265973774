import React, { useState, useEffect } from 'react';
import AuthUser from './Authuser';
import { useLocation } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'; // Make sure to import the CSS for the range slider
import 'bootstrap/dist/css/bootstrap.min.css';

 
function Showproperties(props) {
  const {http} = AuthUser();
    const [projects, setProjects] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedProperty, setSelectedProperty] = useState('');
    const [selectedPropertyType, setSelectedPropertyType] = useState('');
    const [priceRange, setPriceRange] = useState({ min: 0, max: 100 });
    const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(100);
 // Initial range values
 const handleChange = (value) => {
  setPriceRange(value);
  setMinAmount(value.min);
  setMaxAmount(value.max);
};
   // const { projectname } = useParams();
    const locaction = useLocation();
    //console.log(locaction.state.projectname);
    useEffect(()=>{
    if(locaction.state && locaction.state.projectname){
      
   
    
  setSelectedProject(locaction.state.projectname);
  setSelectedPropertyType(locaction.state.projecttype);
    
}
}, [locaction.state.projectname]);
    


    useEffect(() => {
        // Make the API request when the component mounts

        http.get('/projects') // Replace with the actual API endpoint
            .then((response) => {
                
                setProjects(response.data.projects);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        http.get(`/products`) // Include projectname in the URL
            .then((response) => {
                setProducts(response.data.product);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
        }, []);
    // Filter function to determine if a product should be displayed
    const shouldDisplayProduct = (product) => {
        
        if (
            
            (!selectedProject || product.projectname === selectedProject) &&
            (!selectedProperty || product.producttype === selectedProperty) &&
            (!selectedPropertyType || product.projecttype === selectedPropertyType)
        ) {
            return true;
        }
        return false;
    };
    

    return (
        
        <div className="container top-padding">
            <h1 className="text-center pt-5">LATEST PROPERTIES {props.pr}</h1>
            <div className="row ">
                <div className=" pt-4 col-md-4 card mb-4  ">
                    <label htmlFor="type" className="form-label">
                        Select Project
                    </label>
                    <select
                        className="form-control"
                        type=""
                        id="project"
                        name="project"
                        
                        onChange={(e) => setSelectedProject(e.target.value)}
                    >

                        {locaction.state && locaction.state.projectname ? (
                            <>
    <option value={locaction.state.projectname}>
      {locaction.state.projectname}
    </option>
    <option value="">All Projects</option>
    {Array.from(new Set(projects.map((product) => product.projectname)))
    .filter((projectName) => projectName !== locaction.state.projectname) // Filter out the matching project name
    .map((projectName) => (
      <option key={projectName} value={projectName}>
        {projectName}
      </option>
    ))}
    </>
  ) : (
    <>
    <option value="">Select a project</option>
    {Array.from(new Set(projects.map((product) => product.projectname))).map((projectName) => (
        <option value={projectName}>
            {projectName}
            
        </option>
    ))}
    </>
  )}
                        
                        
                    </select>

                    <div className="pt-3">
                        <label htmlFor="type" className="form-label">
                            Select property
                        </label>
                        <select
                            className="form-control"
                            type=""
                            id="property"
                            name="property"
                            onChange={(e) => setSelectedProperty(e.target.value)}
                        >

                            <option value="">Select Property Type</option>
                            <option value="House">House</option>
                            <option value="Apartment">Apartment</option>
                            <option value="Plot">Plot</option>
                        </select>
                    </div>

                    <div className="pt-3">
                        <label htmlFor="type" className="form-label">
                            Select property Type
                        </label>
                        <select
                            className="form-control"
                            type=""
                            id="propertytype"
                            name="propertytype"
                            onChange={(e) => setSelectedPropertyType(e.target.value)}
                        >
                            {locaction.state && locaction.state.projecttype ? (
                            <>
    <option value={locaction.state.projecttype}>
      {locaction.state.projecttype}
    </option>
    <option value="">Both Types</option>

    </>
  ) : (
    <>
                            <option value="">Select Property Type</option>
                            <option value="Commercial">Commercial</option>
                            <option value="Residential">Residential</option>
                            
    </>
    )}
                        </select>

                    </div>
                    <div>
      <InputRange
        maxValue={100}
        minValue={0}
        value={priceRange}
        onChange={handleChange}
      />
      <div className="price-labels">
        <span>Min Amount: {minAmount}</span>
        <span>Max Amount: {maxAmount}</span>
      </div>
    </div>


                    {selectedProperty === 'Apartment' || selectedProperty === 'House' ? (
                                        <>
                    <div className="container pt-4">
                    
                    <h4>Number of Rooms</h4>
  <div className="row pt-3">
  
    <div className="col-md-6">
        
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Rooms1" />
        <label className="form-check-label" htmlFor="Rooms1">
          Rooms 1
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Rooms2" />
        <label className="form-check-label" htmlFor="Rooms2">
        Rooms 2
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Rooms3" />
        <label className="form-check-label" htmlFor="Rooms3">
          Rooms 3
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Rooms4" />
        <label className="form-check-label" htmlFor="Rooms4">
          Rooms 4
        </label>
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Rooms5" />
        <label className="form-check-label" htmlFor="Rooms5">
          Rooms 5
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Rooms6" />
        <label className="form-check-label" htmlFor="Rooms6">
          Rooms 6
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Rooms7" />
        <label className="form-check-label" htmlFor="Rooms7">
          Rooms 7
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Rooms8" />
        <label className="form-check-label" htmlFor="Rooms8">
          Rooms 8
        </label>
      </div>
    </div>
  </div>
</div>

<div className="container pt-3">
<h5>Bathrooms</h5>
  <div className="row pt-3">
    
    <div className="col-md-6 ">
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Bathrooms1" />
        <label className="form-check-label" htmlFor="Bathrooms1">
          Bathrooms 1
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Bathrooms2" />
        <label className="form-check-label" htmlFor="Bathrooms2">
          Bathrooms 2
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Bathrooms3" />
        <label className="form-check-label" htmlFor="Bathrooms3">
          Bathrooms 3
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Bathrooms4" />
        <label className="form-check-label" htmlFor="Bathrooms4">
          Bathrooms 4
        </label>
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Bathrooms5" />
        <label className="form-check-label" htmlFor="Bathrooms5">
          Bathrooms 5
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Bathrooms6" />
        <label className="form-check-label" htmlFor="Bathrooms6">
          Bathrooms 6
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Bathrooms7" />
        <label className="form-check-label" htmlFor="Bathrooms7">
          Bathrooms 7
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="Bathrooms8" />
        <label className="form-check-label" htmlFor="Bathrooms8">
          Bathrooms 8
        </label>
      </div>
    </div>
  </div>
        </div>
        </>
            ) : selectedProperty === 'Plot' ? (
                <>
                <div></div>
                </>
                                    ) : (
                                        <div>
                                        </div>
                                    )}

                </div>

                <div className="col-md-8 ">
                    <div className="row p-5">
                        {products.map((product ) => (
                            shouldDisplayProduct(product) && (
                                <div className="col-md-6" key={product.id}>
                                    <div className="card mb-5 w-100 h-40">
                            <img
                                src={product.imagePaths}
                                alt={product.title}
                                className="card-img-top"
                                style={{ width: '100%', height: '100%' }} // Set fixed width and height
                            />
                            <div className="card-body" style={{fontFamily: 'Time new Roman', fontSize: '16px' }}>
                            <h4 className="card-title">{product.producttype}</h4>
                                <div>{product.productprice}</div>
                                <h4 className="card-title">{product.title}</h4>
                                {product.projectname}
                                <hr className="my-3" />

                                <div className="row w-100 h-20">
    <div className="col">
        {/* Element 1 */}
        <div className="image-left-padding">
            <img width="40%%" height="10%" src="https://img.icons8.com/ios/50/bedroom.png" alt="bedroom"/>
        </div>
        <div>{product.bedroom} Bedroom</div>
    </div>
    <div className="col">
        {/* Element 2 */}
        <div className="image-left-padding">
            <img width="35%%" height="10%" src="https://img.icons8.com/ios-filled/50/wc.png" alt="wc"/>
        </div>
        <div>{product.bathroom} Bathrooms</div>
    </div>
    <div className="col">
        {/* Element 3 */}
        <div className="ml-3">
            <img width="40%%" height="10%" src="https://img.icons8.com/dotty/80/screen-resolution.png" alt="screen-resolution"/>
        </div>
        <div>{product.productsize} SQF</div>
    </div>
</div>

                            </div>
                        </div>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Showproperties;
