import {  useState, useRef,useEffect } from "react";
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import swal from 'sweetalert';
import { useNavigate,useLocation } from 'react-router-dom';
import AuthUser from "./Authuser";

function Payment_IN() {
  const location = useLocation();
  const {http} = AuthUser();
    const navigate = useNavigate();
  const [customer, setCustomer] = useState([]);
  const [cusdata, setcusdata] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [balanceAmount, setBalanceAmount] = useState([]);
  const datePickerRef = useRef(null);

  const [customerData, setCustomerData] = useState({
    customername: '',
    customercode: '',
    invoiceno: '',
    recivedamount: '',
    
  });
  useEffect(() => {
    if(location.state){
    http
      .put('/customers')
      .then((response) => {
        setCustomer(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    setCustomerData({
      customername: location.state.sale.customername,
    customercode: location.state.sale.customercode,
    recivedamount: location.state.sale.recivedamount,
    });
    setInvoice(location.state.sale.invoiceno);

    Calculatepreviousbalance(location.state.sale.customercode);
    calculatebalance();
    toggleSaveButtonVisibility();
  }
  }, []);

  const [selectedDate, setSelectedDate] = useState(new Date());

  // Add a state variable to manage the visibility of the "Save" button
  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  

  const handleProjectNameChange = (event, newValue) => {
    setCustomerData({
      ...customerData,
      customername: newValue.Customer_Name,
      customercode: newValue.Cus_code,
    });

    Calculatepreviousbalance(newValue.Cus_code);

  };

  const Calculatepreviousbalance = async (code) => {
    try {
      const response = await http.put(`/customerdata/${code}`);
      setcusdata(response.data);
      // Calculate and update the previous balance
      
     
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    Previousbalance();
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({
      ...customerData,
      [name]: value,
    });

    // Calculate balance amount
  };

  const handlerecievedamount = (e) => {
    const { name, value } = e.target;

    // Update the received amount in the state
    setCustomerData({
      ...customerData,
      [name]: value,
    });

    calculatebalance();
   
  };
  const calculatebalance = ()=>{

    if (cusdata[0] && cusdata[0].balanceamount) {
      const receivedAmount = parseFloat(customerData.recivedamount);
      const previousBalance = parseFloat(cusdata[0].balanceamount);
      const calculatedBalance = previousBalance - receivedAmount;

      // Update the balance amount in the state
      setBalanceAmount(calculatedBalance);
    }
  }
  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const Previousbalance = () => {
    return (
      <>
        <div className="row pt-4">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="invoiceno" className="form-label pt-2">
                Previous Balance
              </label>
            </div>
          </div>
          <div className="col-md-7">
            <span className="input-group-text">
              <label htmlFor="invoiceno" className="form-label pt-2">
                {cusdata.map((balance) => (
                  <div key={balance.customercode}>
                    {balance.totalbalance || 0}
                  </div>
                ))}
              </label>
            </span>
          </div>
        </div>
      </>
    );
  };

  const handleSave=async(e)=>{
    e.preventDefault();
    const productToAdd = {
        customername: customerData.customername,
        nomineename: '',
        Payment_Type:"Payment_IN",
        invoiceno: invoice,
        totalamount: 0,
        discount: 0,
        netamount: 0,
        recivedamount: customerData.recivedamount,
        balanceamount: 0,
        nomineecode:'',
        customercode:customerData.customercode,
      };
      if(location.state){
        try {
          const response = await http.post('/update_history', productToAdd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          
          console.log(response.data.message);
          if (response.data.message === 'Sale updated successfully') {
              // Reset the form or show a success message
              swal("Good job!", "Payment_IN Save Successfully!", "success");
            navigate(`/Admin/showpaymentin`);
            }
            else{
             // console.error('Error:', response.data);
              
            swal("ERROR!",response.data , "error");
            }
        } catch (error) {
          // Handle any errors here
          console.error('Error saving product:', error);
        }
      }else{
      try {
        const response = await http.post('/cus_history', productToAdd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
        console.log(response.data.message);
        if (response.data.message === 'Payment_IN  saved successfully') {
            // Reset the form or show a success message
            swal("Good job!", "Sale Save Successfully!", "success");
          navigate(`/Admin/showpaymentin`);
          }
          else{
           // console.error('Error:', response.data);
            
          swal("ERROR!",response.data , "error");
          }
      } catch (error) {
        // Handle any errors here
        console.error('Error saving product:', error);
      }
    }
  }
  // Add a function to toggle the visibility of the "Save" button
  const toggleSaveButtonVisibility = () => {
    setIsSaveButtonVisible(!isSaveButtonVisible);
  };

  const handleAddInvoice = () => {
    http
      .put('/customers')
      .then((response) => {
        setCustomer(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    http.post('/invoices')
      .then((response) => {
        const updatedInvoiceNumber = response.data.invoice_number;
        setInvoice(updatedInvoiceNumber);
      })
      .catch((error) => {
        console.error(error);
      });

    // Toggle the visibility of the "Save" button
    toggleSaveButtonVisibility();
  };

  return (
    <div className='container'>
      <h1>Payment_IN</h1>
      <div className='row pt-5'>
        <div className='col-4 left-column'>
          <div className="row">
            <Autocomplete
              id="customername"
              options={customer}
              getOptionLabel={(option) => option.Customer_Name || customerData.customername}
              renderInput={(params) => (
                <TextField {...params} 
                label={customerData.customername ||"Search Customer by Name"}
                 style={{ width: '215px', height: '20px' }} />
              )}
              variant="outlined"

              onChange={handleProjectNameChange}
              getOptionSelected={(option, value) => option.Customer_Name === value.Customer_Name && option.Cus_code === value.Cus_code}
            />
          </div>
          <div className="row pt-5">
            <label htmlFor="title" className="form-label">
              Property Description
            </label>
            <textarea
              type="text"
              className="form-control"
              id="description"
              name="description"
              rows={4}
              cols={40}
              value={customerData.description}
              onChange={handleInputChange}
            />
          </div>

          <div className="row pt-4">
            <div className="col-md-4">
              <div className="form-group">
                
              </div>
            </div>
            <div className="col-md-7">
              {isSaveButtonVisible ? (
                // Render the "Save" button when isSaveButtonVisible is true
                <button
                  id="save-button"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Save
                </button>
              ) : (
                // Render the "Add" button when isSaveButtonVisible is false
                <button
                  id="add-invoice"
                  className="btn btn-primary"
                  onClick={handleAddInvoice}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-4"></div>
        <div className="col-4">
          <div className="right-column">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="invoiceno" className="form-label pt-2">
                    Invoice N.O
                  </label>
                </div>
              </div>
              <div className="col-md-7">
                <span className="input-group-text">
                  <label htmlFor="invoiceno" className="form-label pt-2">
                    {invoice}
                  </label>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="invoiceno" className="form-label pt-2">
                    Date
                  </label>
                </div>
              </div>
              <div className="col-md-7">
                <div className="input-group-append">
                  <span className="input-group-text bg-light">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy h:mm aa"
                      className="form-control border-0"
                      ref={datePickerRef}
                    />
                    <i className="fas fa-calendar-alt" onClick={handleIconClick} style={{ cursor: 'pointer' }}></i>
                  </span>
                </div>
              </div>
            </div>

            {Previousbalance()}

            <div className="row pt-5 mt-5">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="invoiceno" className="form-label pt-2">
                    Received Amount
                  </label>
                </div>
              </div>
              <div className="col-md-7">
                <input
                  type="number"
                  className="form-control"
                  id="recivedamount"
                  name="recivedamount"
                  value={customerData.recivedamount}
                  onChange={handlerecievedamount}
                  required
                />
              </div>
            </div>

            <div className="row pt-4">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="invoiceno" className="form-label pt-2">
                    Balance
                  </label>
                </div>
              </div>
              <div className="col-md-7">
                <span className="input-group-text">
                  <label htmlFor="invoiceno" className="form-label pt-2">
                    {balanceAmount || 0}
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment_IN;
