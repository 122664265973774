import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import placeMarkerIcon from '../images/icons8-place-marker.gif';
import AuthUser from './Authuser';

function Productlist() {
    const {http} = AuthUser();
    const [products, setproducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Make the API request when the component mounts
        http.get('/products') // Replace with the actual API endpoint
            .then((response) => {
                // Log the response data
                setproducts(response.data.product); // Access the 'products' array in the response
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);
    const submitForm = () =>{
        // api call
        
            navigate('/showproperties')
        
    }
    function handleImageClick(productId) {
        // You can now access the productId when the image is clicked
        console.log('Clicked on product with ID:', productId);

       navigate(`/product`,{state:{productId}});
        // You can also use the productId for navigation or any other action you need
        // For example, if you want to navigate to a product details page, you can do:
        // navigate(`/product/${productId}`);
    }
    const firstTenProducts = products.slice(0, 20);

    return (
        <div className="container">
            <h1 className="text-center pt-5">LATEST PROPERTIES</h1>
            <div className="row pt-5">
                {firstTenProducts.map((product) => (
                    <div className="col-md-4" key={product.id}>
                        <div className="card mb-5">
                            <img
                                src={product.imagePaths}
                                alt={product.title}
                                className="card-img-top"
                                
                                style={{ width: '100%', height: '250px' }}
                                onClick={() => handleImageClick(product.unique_code)} // Set fixed width and height
                            />
                            <div className="card-body" style={{fontFamily: 'Time new Roman', fontSize: '16px' }}>
                            <h4 className="card-title">{product.producttype}</h4>
                            <div>RS: {parseFloat(product.productprice).toFixed(0)}</div>
                                <h4 className="card-title">{product.title}</h4>
                                <div className='d-inline-block'>
                                    <img
                                        width="35"
                                        height="35"
                                        src={placeMarkerIcon}
                                        alt="wc"
                                        className="mr-2" // Add some margin to the right of the image for spacing
                                    />
                                    <span className="text-lg">{product.location}</span>
                                    </div>


                                
                                <hr className="my-3" />

                                <div className="row row-left-padding">
                                    {product.producttype === 'Apartment' || product.producttype === 'House' ? (
                                        <>
                                            <div className="row">
                                            <div className="col">
                                                {/* Element 1 */}
                                                <div className="image-left-padding">
                                                <img width="35" height="35" src="https://img.icons8.com/ios/50/bedroom.png" alt="bedroom"/>
                                                </div>
                                                
                                                <div>{product.bedroom} Bedroom</div>
                                                 </div>
                                            
                                            <div className="col">
                                                {/* Element 2 */}
                                                <div className="image-left-padding">
                                                <img width="35" height="35" src="https://img.icons8.com/ios-filled/50/wc.png" alt="wc"/>
                                                </div>
                                                    <div>{product.bathroom} Bathroom</div>
                                                    </div>
                                            
                                            <div className="col">
                                            {/* Element 3 */}
                                            <div className="ml-3">
                                                
                                            <img width="35" height="35" src="https://img.icons8.com/dotty/80/screen-resolution.png" alt="screen-resolution"/>
                                            </div>
                                            <div>{product.productsize} SQF</div>
                                             
                                        </div>
                                        </div>
                                        </>
                                    ) : product.producttype === 'Plot' ? (
                                        <>
                                            <div className="row">
                                            <div className="col">
                                                {/* Element 1 */}
                                                <div className="image-left-padding">
                                                <img width="35" height="35" src="https://img.icons8.com/ios/50/crossroad.png" alt="crossroad"/>
                                                </div>
                                                <div> Street # {product.streetno}</div>
                                            </div>
                                            <div className="col">
                                                {/* Element 2 */}
                                                <div className="image-left-padding">
                                                <img width="35" height="35" src="https://img.icons8.com/ios-glyphs/30/road.png" alt="road"/>
                                                </div>
                                                <div> 
                                                    Street {product.Streetsize} SQF</div>
                                            </div>
                                            <div className="col">
                                            {/* Element 3 */}
                                            <div className="image-left-padding">
                                                
                                                <img width="35" height="35" src="https://img.icons8.com/dotty/80/screen-resolution.png" alt="screen-resolution"/>
                                                </div>
                                            <div>{product.productsize} SQF</div>
                                        </div>
                                        </div>
                                        </>
                                    ) : (
                                        <div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="text-center pt-3">
                <button className="btn btn-primary" onClick={submitForm}>
                    See More
                </button>
            </div>
        </div>
    );
}

export default Productlist;
