// Guest.js
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from '../Navbar';
//import Fetchdata from '../Fetchdata';
import Top from '../Top';
import Login from '../login';
import Body from '../Body';
// import About from '../About';
import ProjectList from '../Projectlist';
import Productlist from '../Productlist';
import Showproperties from '../Showproperties';
import Product from '../Product';
import Register from '../register';

function Guest() {
  const [mode, setmode] = useState('light');
  const [alert, setAlert] = useState(null);

  const showalert = (message, type) => {
    setAlert({
      msg: message,
      type: type
    });
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  }

  const togglemode = () => {
    if (mode === 'dark') {
      setmode('light');
      document.body.style.backgroundColor = 'white';
      showalert("Light Mode Enabled", alert);
    } else {
      setmode('dark');
      document.body.style.backgroundColor = 'gray';
      showalert("Dark Mode Enabled", "Success");
    }
  }

  return (
    <>
      <div className="">
        <Navbar title="Prime Marketing & Builders" about="About us" swithchmode={mode} togglemodes={togglemode} />
        
        <Routes>
          <Route path="/" element={<>
            <Top />
            <Body />

            {/* <TextForm swithchmode={mode} heading="Enter Your Text in Text Analyzer" /> */}
            <ProjectList />
            <Productlist/>
          </>} />
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<Register />} />
          <Route path="/showproperties" element={<Showproperties />} />
          <Route path="/product" element={ <Product/>} />

        </Routes>
      </div>
    </>
  );
}

export default Guest;
