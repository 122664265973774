import React, { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AuthUser from './Authuser';

function Productlist() {
    const {http} = AuthUser();
    const [products, setproducts] = useState([]);
    const [image, setimages] = useState([]);
    const locaction = useLocation();
    const id = locaction.state.productId;

    useEffect(() => {
        // Make the API request when the component mounts
        http.get(`/products/${id}`)
            .then((response) => {
                
                const responseData = response.data.product;
                console.log(responseData);
                setproducts(responseData);
                
                // Check if images are available in the response
                if (responseData.imagePaths) {
                    setimages(responseData.imagePaths);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [id]);
    
    return (
        <div className="container">
            <h1 className="text-center pt-5">LATEST PROPERTIES</h1>
            {products && products.length === 0 ?  ( // Show loading indicator
                <div className="text-center pt-5">
                Loading...
            </div>
            ) : (
            <div className="row pt-5">
                
                    
                        <Carousel showThumbs={false}>
  {image.map((image) => (
    <div key={image}> {/* Each image should be wrapped in a div */}
      <img
        src={image}
        alt={products.title}
        className="card-img-top"
        style={{ width: '100%', height: '680px' }}
      />
    </div>
  ))}
</Carousel>

                            <div className="card-body" style={{fontFamily: 'Time new Roman', fontSize: '16px' }}>
                            <h4 className="card-title">{products.producttype}</h4>
                                <div>{products.productprice}</div>
                                <h4 className="card-title">{products.title}</h4>
                                <hr className="my-3" />

                                <div className="row row-left-padding">
                                    {products.producttype === 'Apartment' || products.producttype === 'House' ? (
                                        <>
                                            <div className="row">
                                            <div className="col">
                                                {/* Element 1 */}
                                                <div className="">
                                                <img width="35" height="35" src="https://img.icons8.com/ios/50/bedroom.png" alt="bedroom" />
                                                </div>
                                                <div>{products.bedroom} Bedroom</div>
                                            </div>

                                            <div className="col">
                                                {/* Element 2 */}
                                                <div className="">
                                                <img width="35" height="35" src="https://img.icons8.com/ios-filled/50/wc.png" alt="wc" />
                                                </div>
                                                <div>{products.bathroom} Bathroom</div>
                                            </div>

                                            <div className="col">
                                                {/* Element 3 */}
                                                <div className="ml-3">
                                                <img width="35" height="35" src="https://img.icons8.com/dotty/80/screen-resolution.png" alt="screen-resolution" />
                                                </div>
                                                <div>{products.productsize} SQF</div>
                                            </div>
                                            </div>

                                        </>
                                    ) : products.producttype === 'Plot' ? (
                                        <>
                                            <div className="row">
                                            <div className="col">
                                                {/* Element 1 */}
                                                <div className="image-left-padding">
                                                <img width="35" height="35" src="https://img.icons8.com/ios/50/crossroad.png" alt="crossroad"/>
                                                </div>
                                                <div> Street # {products.streetno}</div>
                                            </div>
                                            <div className="col">
                                                {/* Element 2 */}
                                                <div className="image-left-padding">
                                                <img width="35" height="35" src="https://img.icons8.com/ios-glyphs/30/road.png" alt="road"/>
                                                </div>
                                                <div> 
                                                    Street {products.Streetsize} SQF</div>
                                            </div>
                                            <div className="col">
                                            {/* Element 3 */}
                                            <div className="image-left-padding">
                                                
                                                <img width="35" height="35" src="https://img.icons8.com/dotty/80/screen-resolution.png" alt="screen-resolution"/>
                                                </div>
                                            <div>{products.productsize} SQF</div>
                                        </div>
                                        </div>
                                        </>
                                    ) : (
                                        <div>
                                            
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        
                    
            )}

        </div>
    );
}

export default Productlist;
