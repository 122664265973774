import { Routes, Route } from 'react-router-dom';

import React, { useState } from 'react';
import Register from '../register';
import MainNavbar from '../MainNavbar';
import Product from '../Addproduct';
import Project from '../AddProjects';
import AuthUser from '../Authuser';
// import Sidebar from '../Sidebar';
import AddCustomer from '../AddCustomer';
import AddNominee from '../AddNominee';
import ShowCustomer from '../ShowCustomer';
import UpdateCustomer from '../UpdateCustomer';
import Shownominee from '../ShowNominee';
import Top from '../Top';
import ShowProduct from '../ShowProduct'
import ShowSale from '../Showsale'
import Sale from '../Sale'
import PaymentIN from '../Payment_IN';
import Showpaymentin from '../Showpaymentin';
import Customerverification from '../Customerverification';

function Guest() {
    const [mode, setmode] = useState('light');
  const [alert, setAlert] = useState(null);
  
  const {token,logout} = AuthUser();

    const logoutUser = () => {
        if(token !== undefined){
            logout();
        }
      }
  const showalert = (message, type) => {
    setAlert({
      msg: message,
      type: type
    });
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  }

  const togglemode = () => {
    if (mode === 'dark') {
      setmode('light');
      document.body.style.backgroundColor = 'white';
      showalert("Light Mode Enabled", alert);
    } else {
      setmode('dark');
      document.body.style.backgroundColor = 'gray';
      showalert("Dark Mode Enabled", "Success");
    }
  }
    return (
        <>
           
           
            <div className="">
            <MainNavbar title="Prime Marketing" about="Registeration" product="Product" swithchmode={mode} togglemodes={togglemode} logout={logoutUser} />
                             
                <Routes>
                { <Route exact path="/admin" element={<>   <Top/>  </>} />}
                <Route exact path="/Admin/addcustomer" element={<>    <AddCustomer/></>} />
                <Route exact path="/Admin/paymentin" element={<>    <PaymentIN /></>} />
                <Route exact path='/Admin/addNominee' element={<> <AddNominee/> </>}/>
                <Route exact path='/Admin/showCustomer' element={<> <ShowCustomer/> </>}/>
                <Route exact path='/Admin/updateCustomer' element={<> <UpdateCustomer/> </>}/>
                <Route exact path='/Admin/shownominee' element={<> <Shownominee/> </>}/>
                <Route exact path='/Admin/sale' element={<> <Sale/> </>}/>
                <Route exact path='/Admin/Showproduct' element={<> <ShowProduct/> </>}/>
                
                <Route exact path="/Admin/register" element={<>    <Register /></>} />
                <Route exact path="/Admin/product" element={<>    <Product /></>} />
                <Route exact path="/Admin/project" element={<>    <Project /></>} />
                <Route exact path="/Admin/showsale" element={<>    <ShowSale /></>} />
                <Route exact path="/Admin/showpaymentin" element={<>    <Showpaymentin /></>} />
                <Route exact path="/Admin/customerverification" element={<>    <Customerverification /></>} />

                </Routes>
            </div>
           

        </>
    );

}
export default Guest;