import { Routes, Route } from 'react-router-dom';

import React, { useState } from 'react';

import MainNavbar from '../Customernavbar';
import AuthUser from '../Authuser';


import Customerverification from '../Customerverification';

function Guest() {
    const [mode, setmode] = useState('light');
  const [alert, setAlert] = useState(null);
  
  const {token,logout} = AuthUser();

    const logoutUser = () => {
        if(token !== undefined){
            logout();
        }
      }
  const showalert = (message, type) => {
    setAlert({
      msg: message,
      type: type
    });
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  }

  const togglemode = () => {
    if (mode === 'dark') {
      setmode('light');
      document.body.style.backgroundColor = 'white';
      showalert("Light Mode Enabled", alert);
    } else {
      setmode('dark');
      document.body.style.backgroundColor = 'gray';
      showalert("Dark Mode Enabled", "Success");
    }
  }
    return (
        <>
           
           
            <div className="">
            <MainNavbar title="Prime Marketing" about="Registeration" product="Product" swithchmode={mode} togglemodes={togglemode} logout={logoutUser} />
                             
                <Routes>
                
                <Route exact path="/customer/customerverification" element={<>    <Customerverification /></>} />

                </Routes>
            </div>
           

        </>
    );

}
export default Guest;