import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
//import swal from 'sweetalert';
import AuthUser from './Authuser';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const ProductForm = (props) => {
    const { show, handleClose, product , updateproduct} = props;

    const [sliderImages, setSliderImages] = useState([]);
  const [sliderprevImages, setSliderprevImages] = useState([]);
  const {http} = AuthUser();

   // State for multiple slider images
  const [thumbnailImages, setThumbnailImages] = useState([]);
  const [thumbnailsavImages, setThumbnailsavImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [projects, setProjects] = useState([]);

    const [productInfo, setProductInfo] = useState({
        title: '',
         // Default product type
        category: '',
        projecttype:'',
        producttype:'Select A Property',
        Streetsize: '',
        productsize:'',
        bedroom: '',
        bathroom: '',
        productprice: '',
        projectname:'',
        streetno:'',
        location:'',
        desription:'',
      });
    
      useEffect(() => {
        // Make the API request when the component mounts
        http.get('/projects') // Replace with the actual API endpoint
            .then((response) => {
                setProjects(response.data.projects);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);
    useEffect(() => {
        // Set the form data to the selected product's data when it changes
        if (product) {

          setProductInfo({
            title: product.title || '',
            category: product.category || '',
            productsize: product.productsize || '',
            projecttype: product.projecttype || '',
            producttype: product.producttype || '',
            Streetsize: product.Streetsize || '',
            bedroom: product.bedroom || '',
            bathroom: product.bathroom || '',
            productprice: product.productprice || '',
            projectname: product.projectname || '',
            streetno: product.streetno || '',
            location: product.location || '',
            desription: product.location || '',
            
            
          });
          
          thumbnailImages[0]= product.imagePaths;

          product.sliderpath.forEach((products,index) => {
            // Create a new JSX element for each item
            sliderprevImages[index] = product.sliderpath[index];
           // console.log(product.sliderpath[index]);
          });
          

          
        }
      }, [product]);

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductInfo({
          ...productInfo,
          [name]: value,
        });
      };

      const handleFileInputChange = (e, imageType) => {
        const files = e.target.files;
        if (files.length > 0) {
          // Filter valid images
          const validImages = Array.from(files).filter((file) =>
            ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(file.type)
          );
      
          if (imageType === 'slider') {
            setCurrentImageIndex(0);
            setSliderImages(e.target.files);
            console.log(sliderImages.length);
            setSliderprevImages(validImages.map((file) => URL.createObjectURL(file)));
          } else if (imageType === 'thumbnail') {
            setThumbnailsavImages(e.target.files);
            setThumbnailImages(validImages.map((file) => URL.createObjectURL(file)));
          } else {
            alert('Invalid image type');
          }
        }
      };
      
      
      // const goToPreviousImage = () => {
      //   if (sliderImages.length > 0) {
      //     setCurrentImageIndex((prevIndex) =>
      //       prevIndex === 0 ? sliderprevImages.length - 1 : prevIndex - 1
      //     );
      //   }
      // };
    
      // const goToNextImage = () => {
      //   if (sliderImages.length > 0) {
      //     setCurrentImageIndex((prevIndex) =>
      //       prevIndex === sliderprevImages.length - 1 ? 0 : prevIndex + 1
      //     );
      //   }
      // };
      
      useEffect(() => {
        if (sliderprevImages.length === 0) {
          setCurrentImageIndex(0); // Reset the current image index when the sliderImages array is empty
        }
      }, [sliderprevImages]);
    
      useEffect(() => {
        // Implement a timer to automatically advance the slideshow
        const timer = setInterval(() => {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sliderprevImages.length);
        }, 3000); // Adjust the interval (in milliseconds) for the slideshow
    
        // Clear the timer when the component unmounts
        return () => clearInterval(timer);
      }, [sliderImages]);
      
      
    
      // Handle file input change for thumbnail image
     
    
      const handleSubmit = async (e) => {

        e.preventDefault();
      
        const formData = new FormData();
         formData.append('title', productInfo.title);
         formData.append('projectname', productInfo.projectname);
    formData.append('projecttype', productInfo.projecttype);
    formData.append('producttype', productInfo.producttype); // Update 'projecttype' to 'type'
    formData.append('category', productInfo.category);
    formData.append('Streetsize', productInfo.Streetsize);
    formData.append('productsize', productInfo.productsize);
    formData.append('streetno', productInfo.streetno);
    formData.append('bedroom', productInfo.bedroom);
    formData.append('bathroom', productInfo.bathroom);
    formData.append('location', productInfo.location);
    formData.append('productprice', productInfo.productprice);
    // console.log(formData);
    //formData.append('images', JSON.stringify(sliderImages));
        // Append the selected images to the form data
        // for (let i = 0; i < sliderImages.length; i++) {  
        //   //console.log(sliderImages); 
        //   formData.append('images[]', sliderImages[i]);
          
        // }
        console.log(sliderImages.length);
        for (let i = 0; i < sliderImages.length; i++) {
          formData.append('slider_images[]', sliderImages[i]);
          console.log(sliderImages.length);
      }
    
      // Append the selected thumbnail images to the form data
      for (let i = 0; i < thumbnailsavImages.length; i++) {
          formData.append('thumbnail_images[]', thumbnailsavImages[i]);
      }
      console.log(thumbnailsavImages);
      console.log(formData);

      try {
        const response = await http.post(`/updateproduct/${product.unique_code}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      
        // Assuming your API returns data including 'nominee' with updated data
        const updatedproduct = response.data;
      
        // Update the 'nominees' state with the updated nominee
        
        updateproduct((prevproducts) => {
          // Find the index of the updated nominee in the existing state
          const updatedIndex = prevproducts.findIndex((c) => c.unique_code === updatedproduct.unique_code );
            
          // Create a new array with the updated nominee replaced
          const updateproducts = [...prevproducts];
          updateproducts[updatedIndex] = updatedproduct;
      
          return updateproducts;
         
        });
       
        handleClose();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      };
      
      const renderBedroomBathroomFields = () => {
        if (productInfo.producttype === 'Apartment' || productInfo.producttype === 'House') {
          return (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="bedrooms" className="form-label">
                      Number of Bedrooms
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="bedroom"
                      name="bedroom"
                      value={productInfo.bedroom}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="bathrooms" className="form-label">
                      Number of Bathrooms
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="bathroom"
                      name="bathroom"
                      value={productInfo.bathroom}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </>
          );
        }
        if (productInfo.producttype === 'Plot') {
            return (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="bedrooms" className="form-label">
                        Street #
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="streetno"
                        name="streetno"
                        value={productInfo.streetno}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="bathrooms" className="form-label">
                        Street Size(SQF)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="Streetsize"
                        name="Streetsize"
                        value={productInfo.Streetsize}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          }
    
        return null;
      };
      const handleProjectNameChange = (e) => {
        const selectedProjectName = e.target.value;
    
        // Find the corresponding project object from the projects array
        const selectedProject = projects.find((project) => project.projectname === selectedProjectName);
    
        if (selectedProject) {
          // Update the project type in the state based on the selected project
          setProductInfo({
            ...productInfo,
            projectname: selectedProjectName,
            projecttype: selectedProject.projecttype, // Assuming 'projecttype' is the correct property name
          });
        }
      };

  return (
    <>
   
   <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>ADD product DETAILS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
      

    <div className='card '>
    <form onSubmit={handleSubmit}>

    <div className="container">
      <h2>Product Information</h2>
      <div className="card-body m-3">
      

        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Product Title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={productInfo.title}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="row">
        <div className="col-md-6">
        <div className="mb-3">
          <label htmlFor="type" className="form-label">
            Project Name
          </label>
          <select
                        className="form-control"
                        type=""
                        id="projectname"
                        name="projectname"
                        value={productInfo.projectname} 
                        onChange={handleProjectNameChange}
                    >
                        <option value="">Select an option</option>
                        {Array.from(new Set(projects.map((product) => product.projectname))).map((projectName) => (
                            <option value={projectName}>
                                {projectName}
                                
                            </option>
                        ))}
                    </select>
        </div>
        </div>
        <div className="col-md-6">
        <div className="mb-3">
          <label htmlFor="prjecttype" className="form-label">
            Project Type
          </label>
          <input
            type="text"
            className="form-control"
            id="projecttype" // Check for typos here
            name="projecttype"
            value={productInfo.projecttype}
            onChange={handleInputChange}
            required
          />

        </div>
        </div>

        <div className="col-md-6">
        <div className="mb-3">
          <label htmlFor="type" className="form-label">
          Property Type
          </label>
          <select
            className="form-control"
            type=""
            id="producttype"
            name="producttype"
            value={productInfo.producttype}
            onChange={handleInputChange}
            required
          >
            <option value="Select Property Type">Select Property Type</option>
            <option value="House">House</option>
            <option value="Apartment">Apartment</option>
            <option value="Plot">Plot</option>
          </select>
        </div>
        </div>
        <div className="col-md-6">
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
          Block Name
          </label>
          <input
            type="text"
            className="form-control"
            id="category"
            name="category"
            value={productInfo.category}
            onChange={handleInputChange}
            required
          />
        </div>
        </div>
        {renderBedroomBathroomFields()}
        <div className="col-md-6">
        <div className="mb-3">
          <label htmlFor="size" className="form-label">
            Product Size(SQF)
          </label>
          <input
            type="number"
            className="form-control"
            id="productsize"
            name="productsize"
            value={productInfo.productsize}
            onChange={handleInputChange}
            required
          />
        </div>
        </div>
        <div className="col-md-6">
        <div className="mb-3">
          <label htmlFor="price" className="form-label">
            Product Price
          </label>
          <input
            type="number"
            className="form-control"
            id="productprice"
            name="productprice"
            value={productInfo.productprice}
            onChange={handleInputChange}
            required
          />
        </div>
        </div>
        
        
        </div>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Property Location
          </label>
          <input
            type="text"
            className="form-control"
            id="location"
            name="location"
            value={productInfo.location}
            onChange={handleInputChange}
            required
          />
        
      </div>
      <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Property Description
          </label>
          <textarea
            type="text"
            className="form-control"
            id="desription"
            name="desription"
            rows={4} // Number of visible text rows
        cols={40}
            value={productInfo.desription}
            onChange={handleInputChange}
            required
          />
        
      </div>
    </div>
    </div>
    <div className="card m-5">
        <div className="container">
          <h2>Product Images</h2>
          <div className="card-body m-3">
  <div className="row">
    <div className="col-md-6">
  <div className="input-group">
    <input
      type="file"
      className="form-control"
      id="fileInput1"
      style={{ display: 'none' }}
      onChange={(e) => handleFileInputChange(e, 'slider')}
      multiple
      accept="image/jpeg, image/png, image/jpg, image/gif"
    />
    {sliderprevImages.length > 0 ? (
      <div className="d-flex justify-content-center align-items-center">
        
        <img
          src={sliderprevImages[currentImageIndex]}
          alt={`Slider Preview ${currentImageIndex}`}
          key={sliderprevImages[currentImageIndex]}
          className="img-small"
        />
      </div>
    ) : (
      <label
        htmlFor="fileInput1"
        className="form-control border border-primary p-1 cursor-pointer d-flex justify-content-center align-items-center"
      >
        <i className="fa fa-cloud-upload-alt fa-4x ml-1"></i>
      </label>
    )}
  </div>
</div>

    <div className="col-md-6">
      <div className="input-group">
        <input
          type="file"
          className="form-control "
          id="fileInput2"
          style={{ display: 'none' }}
          onChange={(e) => handleFileInputChange(e, 'thumbnail')}
        />
        <label
  htmlFor="fileInput2"
  className="form-control border border-primary p-1 cursor-pointer d-flex justify-content-center align-items-center"
>
{thumbnailImages && thumbnailImages.length > 0 ? (
        <img src={thumbnailImages[0]} alt="Thumbnail Preview" className="img-small" />
      ) : (
        <i className="fa fa-cloud-upload-alt fa-4x ml-1"></i>
      )}
</label>



      </div>
    </div>
  </div>
</div>


        </div>
      </div>
      <div className="d-grid gap-2 p-2">
      <button type="submit" className="btn btn-primary mt-3">
        Save Changes
        </button>
        <Button variant="secondary" onClick={() => { handleClose();}}>
          Close
        </Button>
        </div>
      </form>
      </div>
        
      
</Modal.Body>

      <Modal.Footer>
      
        
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default ProductForm;
