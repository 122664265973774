import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthUser from './Authuser';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const NomineeForm = (props) => {
  const {http} = AuthUser();
    const { show, handleClose, nominee , updatenominee} = props;

    const [thumbnailImages, setThumbnailImages] = useState([]);
    const [nomineesavImages, setThumbnailsavImages] = useState([]);
    const [phoneValid, setPhoneValid] = useState(true);

    const [nomineeData, setFormData] = useState({
      nomineeName: '',
      fatherName: '',
      cnic: '',
      email: '',
      phone: '',
      nationality: '',
      address: '',
      // Add more fields as needed
    });
    
     
    useEffect(() => {
        // Set the form data to the selected nominee's data when it changes
        if (nominee) {
          setFormData({
            nomineeName: nominee.Nominee_Name || '',
            fatherName: nominee.Relation || '',
            cnic: nominee.Cnic || '',
            email: nominee.Email || '',
            phone: nominee.Phone || '',
            nationality: nominee.Nationality || '',
            address: nominee.Address || '',
            
          });
          
          thumbnailImages[0]= nominee.imagePaths;
        }
      }, [nominee]);

      const handleFileInputChange = (e, imageType) => {
        const file = e.target.files[0]; // Get the selected file
      
        if (file) {
          // Check if a file is selected
          // Check if the selected file type is an image
          if (['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(file.type)) {
            // Set the image file as the image preview
            setThumbnailImages([URL.createObjectURL(file)]);
            setThumbnailsavImages(file);
          } else {
            alert('Invalid image type');
          }
        }
      };
      
    
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...nomineeData, [name]: value });
      
      
    };
    const validatePhoneNumber = (phoneNumber) => {
      if (phoneNumber.startsWith('+') || phoneNumber.match(/^\d{1,4}$/)) {
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
        const isValid = parsedPhoneNumber ? parsedPhoneNumber.isValid() : true;
        setPhoneValid(isValid);
      } else {
        // If no country code is provided, consider it valid (or you can apply different logic)
        setPhoneValid(false);
      }
    };
    
  
    const handleBlur = () => {
      // Validate the phone number when the input field loses focus
      validatePhoneNumber(nomineeData.phone);
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Handle form submission here (e.g., send data to the server)
        if (phoneValid) {
          // Phone number is valid, proceed with form submission
      
          const formData = new FormData();
      
          formData.append('nomineeName', nomineeData.nomineeName);
          formData.append('fatherName', nomineeData.fatherName);
          formData.append('Cnic', nomineeData.cnic);
          formData.append('email', nomineeData.email);
          formData.append('phone', nomineeData.phone);
          formData.append('nationality', nomineeData.nationality);
          formData.append('address', nomineeData.address);
      
          // Append the selected thumbnail images to the form data
          
            formData.append(`nominee_Image[]`, nomineesavImages);
           
      
          // After handling the form submission in your handleSubmit function
          try {
            const response = await http.post(`/update-nominee/${nominee.Nom_code}`, formData);
          
            // Assuming your API returns data including 'nominee' with updated data
            const updatednominee = response.data;
          
            // Update the 'nominees' state with the updated nominee
            
            updatenominee((prevnominees) => {
              // Find the index of the updated nominee in the existing state
              const updatedIndex = prevnominees.findIndex((c) => c.Nom_code === updatednominee.Nom_code );
                
              // Create a new array with the updated nominee replaced
              const updatednominees = [...prevnominees];
              updatednominees[updatedIndex] = updatednominee;
          
              return updatednominees;
             
            });
            handleClose();
          } catch (error) {
            console.error('Error fetching data:', error);
          }
          
          
        } else {
          // Phone number is invalid, show an error message
          alert('Invalid phone number. Please correct it before submitting.');
        }
      };
      

  return (
    <>
   
   <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>ADD nominee DETAILS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
      <form onSubmit={handleSubmit}>
        <div className='card m-1 mt-5'>
          <h5>nominee Information</h5>
        
        <div className='card-body m-1'>
        <div className="row">
    <div className="col-sm-9">
        <div className="form-group">
          <label htmlFor="firstName">nominee Name</label>
          <input
            type="text"
            className="form-control"
            id="nomineeName"
            name="nomineeName"
            value={nomineeData.nomineeName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Father Name</label>
          <input
            type="text"
            className="form-control"
            id="fatherName"
            name="fatherName"
            value={nomineeData.fatherName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="cnic">CNIC</label>
          <input
            type="text"
            className="form-control"
            id="cnic"
            name="cnic"
            value={nomineeData.cnic}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="cnic">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={nomineeData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className='row'>
        <div className='col'>
        <div className="form-group">
    <label htmlFor="phone">Phone N.O</label>
    <input
      type="tel"
      className={`form-control ${phoneValid ? '' : 'is-invalid'}`}
      id="phone"
      name="phone"
      value={nomineeData.phone}
      onChange={handleChange}
      onBlur={handleBlur}
      required
    />
    {/* Display a validation feedback message */}
    {!phoneValid && (
      <div className="invalid-feedback">Invalid phone number.</div>
    )}
  </div>
        </div>

        <div className='col'>
        <div className="form-group">
          <label htmlFor="nationality">Nationality</label>
          <input
            type="text"
            className="form-control"
            id="nationality"
            name="nationality"
            value={nomineeData.nationality}
            onChange={handleChange}
            required
          />
        </div>
        </div>
        </div>
        <div className="form-group">
          <label htmlFor="cnic">Address</label>
          <textarea
            type="text"
            className="form-control"
            id="address"
            name="address"
            value={nomineeData.address}
            onChange={handleChange}
            required
          />
        </div>
        </div>
        <div className='col mt-2'>

        <div className="input-group">
  <input
    type="file"
    className="form-control"
    id="fileInput2"
    style={{ display: 'none' }}
    onChange={(e) => handleFileInputChange(e, 'thumbnail')}
  />
  <label
    htmlFor="fileInput2"
    className="form-control border border-primary border-5 cursor-pointer d-flex justify-content-center align-items-center"
    style={{ width: '140px', height: '220px' }}
  >
    {thumbnailImages && thumbnailImages.length > 0 ? (
      <img src={thumbnailImages[0]} alt="Thumbnail Preview" className="img-cus" />
    ) : (
      <i className="fa fa-cloud-upload-alt fa-4x ml-1"></i>
    )}
  </label>
</div>

        </div>
        </div>
        
       
        {/* Add more form fields here */}
        <button type="submit" className="btn btn-primary mt-3">
        Save Changes
        </button>
        <Button variant="secondary" onClick={() => { handleClose();}}>
          Close
        </Button>
    
        </div>
        </div>
  </form>
</Modal.Body>

      <Modal.Footer>
      
        
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default NomineeForm;
