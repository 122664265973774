import React, { Component } from 'react'

export default class  extends Component {
  render() {
    return (
      <div className="text-center pt-5">
        <h2>EXPLORE REALESTATE ON PRIME MARKETING</h2>

        <p>Take a deep dive and browse homes,plots and appartmetns for sale, original neighborhood photos, resident <br /> reviews and local insights to find what is right for you.</p>
      </div>
      
    )
  }
}
