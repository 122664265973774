import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function Navbar(props) {
  return (
    <nav className={`navbar navbar-expand-lg navbar-${props.switchmode} bg-${props.switchmode} fixed-top navbar-light bg-dark border-2`}>
      <div className="container-fluid">
        <Link className="navbar-brand fw-bold text-light" to="/">
          {props.title}
        </Link>

        <button
          className="navbar-toggler text-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggleExternalContent"
          aria-controls="navbarToggleExternalContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon bg-white"></span>
        </button>

        <div className="collapse navbar-collapse text-light" id="navbarToggleExternalContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active text-light" aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-light" to="/about">
                {props.about}
              </Link>
            </li>
          </ul>
          <form className="d-flex" role="search">
            
            <Link to="/login">
              <button className="btn btn-outline-primary text-light">Login</button>
            </Link>
          </form>
          <div className={`form-check form-switch text-${props.switchmode === 'dark' ? 'light' : 'dark'} mx-3`}>
            <input className="form-check-input" type="checkbox" onClick={props.togglemodes} id="flexSwitchCheckChecked" />
            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
              Enable Dark Mode
            </label>
          </div>
        </div>
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  about: PropTypes.string,
  switchmode: PropTypes.string,
  togglemodes: PropTypes.func,
};

Navbar.defaultProps = {
  title: 'Set Title',
  about: 'Set about',
};
