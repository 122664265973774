import React, { Component } from 'react';



export default class Top extends Component {
  render() {
    return (
      <div className="home_content expand-lg" >
        <span className="badge bg-dark main1_left-margin style={{ fontSize: '2vw', fontFamily: 'sans-serif' }}">A Vision For Your Life</span>
                  <h1 className="hero_title main1_left-margin " style={{ fontSize: '3vw', fontFamily: 'sans-serif' }}>
            PRIME MARKETING 
            <div className="main_left-margin" style={{ fontSize: '3vw', fontFamily: 'sans-serif' }}>  & </div>
            <div className="" style={{ fontSize: '3vw', fontFamily: 'sans-serif' }}> BUILDERS </div>
          </h1>

                    
                    <p className="hero_desc main1_left-margin " style={{ fontSize: '2vw' }}>
                        Your Lifeline Destination
                    </p>
                    {/* <div className="d-flex gap-2 pt-5">
                    <button type="button" className="btn btn-outline-primary">BUY</button>
                   <button type="button" className="btn btn-outline-primary">RENT</button>
</div> */}


                    {/* <div className="row g-3 pt-3">
                  
                    <div className="col">
                    <input type="text" placeholder="Location" className="form-control"/>
                    </div>
                    
                    <div className="col">
                                    <input type="text" placeholder="Property" className="form-control"/>
                                    </div>
                                    <div className="col">
                                    <input type="text" placeholder="Price" className="form-control"/>
                                    </div>
                                    <div className="col">
                                    <button className="btn btn-primary">
                                        <i className="bx bx-search"></i>
                                        <span>Search</span>
                                    </button>
                                    </div>

                    
                    </div> */}
      </div>
    );
  }
}
